/* eslint-disable camelcase */
export interface IUpdateProjectDTO {
  name?: string;
  description?: string;
  state?: string;
  start_date?: string;
  end_date?: string;
  category_id?: string;
}

export default class UpdateProjectDTO {
  public name?: string;

  public state?: string;

  public description?: string;

  public start_date?: string;

  public end_date?: string;

  public category_id?: string;

  constructor(data: IUpdateProjectDTO) {
    this.name = data.name;
    this.state = data.state;
    this.description = data.description;
    this.start_date = data.start_date;
    this.end_date = data.end_date;
    this.category_id = data.category_id;
  }
}

import Contact from '@/model/Classes/Contact';
import { ProductsService } from '@/services/ProductsService';
import { ProjectsService } from '@/services/ProjectsService';
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';

@Options({
  name: 'ContactModal',
  props: {
    projectId: {
      type: String,
      required: false,
    },
    contactData: {
      type: Object as () => Contact,
      required: false,
    },
  },
  watch: {
    contactData(newVal: Contact) {
      this.contact.email = this.contactData.contactEmail;
      this.contact.phone = this.contactData.contactPhone;
      this.contact.meetingsUrl = this.contactData.contactMeetingUrl;
      this.contactUpdate.email = this.contactData.contactEmail;
      this.contactUpdate.phone = this.contactData.contactPhone;
      this.contactUpdate.meetingsUrl = this.contactData.contactMeetingUrl;
    },
  },
  data() {
    return {
      contact: {
        email: 'No establecido',
        phone: 'No establecido',
        meetingsUrl: '',
      },
      contactUpdate: {
        email: '',
        phone: '',
        meetingsUrl: '',
      },
      isCompleted: false,
      editSessionOn: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isUserAdmin', 'isUserProducer']),
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    activateEdition() {
      this.editSessionOn = true;
    },
    cancelEdition() {
      this.editSessionOn = false;
      this.contactUpdate.email = '';
      this.contactUpdate.phone = '';
      this.contactUpdate.meetingsUrl = '';
    },
    async saveContactInfo() {
      try {
        if (this.contactUpdate.email === ''
          && this.contactUpdate.phone === ''
          && this.contactUpdate.meetingsUrl === '') {
          alert('No hay datos para actualizar');
        } else {
          const data = {
            contact_email: this.contactUpdate.email !== '' ? this.contactUpdate.email : undefined,
            contact_phone: this.contactUpdate.phone !== '' ? this.contactUpdate.phone : undefined,
            contact_meeting_url: this.contactUpdate.meetingsUrl !== '' ? this.contactUpdate.meetingsUrl : undefined,
          };
          await ProjectsService.updateProject(this.projectId, data);
          this.contact.email = data.contact_email;
          this.contact.phone = data.contact_phone;
          this.contact.meetingsUrl = data.contact_meeting_url;
          this.$emit('update-contact-info');
          this.cancelEdition();
        }
      } catch (error) {
        this.cancelEdition();
        alert(`Error actualizando información de contacto para el proyecto: ${error}`);
      }
    },
  },
})

export default class ContactModal extends Vue {}

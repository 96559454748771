/* eslint-disable class-methods-use-this */
import { APIService } from './ApiService';

class Service {
  public getProfiles() {
    const apiPath = '/profile';
    const response = APIService.get(apiPath);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const ProfilesService = new Service();

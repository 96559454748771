export default {
  getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  },

  getCurrentDateUTC() {
    return new Date().toJSON();
  },
  getCurrentDateUTCOnlyDate() {
    return new Date().toJSON().slice(0, 10);
  },
  formatUTCDate(date: string) {
    if (date === '' || date === null || date === undefined) {
      return 'No establecida';
    }
    return date.slice(0, 10);
  },
  returnRandomProjectImage() {
    const images = [
      'https://lacordadaestudio.com/wp-content/uploads/2022/08/seccion-servicios-y-nosotros.png',
      'https://lacordadaestudio.com/wp-content/uploads/2022/06/Etapa-3-1.jpg',
      'https://lacordadaestudio.com/wp-content/uploads/2021/07/5.png',
      'https://lacordadaestudio.com/wp-content/uploads/2021/07/3.png',
      'https://lacordadaestudio.com/wp-content/uploads/2022/05/%C2%A1Bienvenido-Empezando-Con-El-Pie-Derecho.png',
    ];
    return images[Math.floor(Math.random() * 4)];
  },
  returnCapitalizedString(stringC: string) {
    if (stringC) {
      const arr = stringC.split(' ');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(' ');
      return str2;
    }
    return stringC;
  },
  returnStateVerbose(state: string) {
    switch (state) {
      case 'todo':
        return 'Por Hacer';
      case 'process':
        return 'En Proceso';
      case 'done':
        return 'Terminado';
      default:
        return 'Por Hacer';
    }
  },
  returnTaskStateVerbose(state: string) {
    switch (state) {
      case '0':
        return 'Por Hacer';
      case '0.5':
        return 'En Proceso';
      case '1':
        return 'Terminado';
      default:
        return 'Por Hacer';
    }
  },
  setTokenOnCookies(userToken: string) {
    document.cookie = `token=${userToken}`;
  },
  removeTokenFromCookies() {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  },
  getTokenFromCookie() {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].split('=');
      if (cookie[0] === 'token') {
        return cookie[1];
      }
    }
    return null;
  },
};

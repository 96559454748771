/* eslint-disable class-methods-use-this */
import { ISendAuthDTO } from '@/model/DTOs/SendAuthDTO';
import { APIService } from './ApiService';
import { APIDocumentsService } from './ApiDocumentsService';

class Service {
  public autenticateUser(body: ISendAuthDTO) {
    const apiPath = '/auth/login';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public getAllUsers() {
    const apiPath = '/user';
    const response = APIService.get(apiPath);
    return response;
  }

  public getUserById(id: string) {
    const apiPath = `/user/${id}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public createUser(body: object) {
    const apiPath = '/user';
    const response = APIService.post(apiPath, body);
    return response;
  }

  // uses a diferent service in order to upload documents
  public createUserEnterprise(formData: object) {
    const apiPath = '/user';
    const response = APIDocumentsService.post(apiPath, formData);
    return response;
  }

  public updateUser(id: string, body: object) {
    const apiPath = `/user/${id}`;
    const response = APIService.put(apiPath, body);
    return response;
  }

  public updateUserImage(id: string, formData: any) {
    const apiPath = `/user/${id}/image`;
    const response = APIDocumentsService.put(apiPath, formData);
    return response;
  }

  public updateUserPassword(id: string, data: object) {
    const apiPath = `/user/${id}/password`;
    const response = APIService.put(apiPath, data);
    return response;
  }

  public deleteUser(id: string) {
    const apiPath = `/user/${id}`;
    const response = APIService.delete(apiPath);
    return response;
  }

  public requestPasswordReset(body: object) {
    const apiPath = 'auth/request-reset-password';
    const response = APIService.patch(apiPath, body);
    return response;
  }

  public resetPassword(body: object) {
    const apiPath = 'auth/reset-password';
    const response = APIService.patch(apiPath, body);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const UsersService = new Service();

import { Options, Vue } from 'vue-class-component';
import { defineAsyncComponent } from 'vue';

import Task from '@/model/Classes/Task';
import { User } from '@/model/Classes/User';
import CreateTaskDTO from '@/model/DTOs/CreateTaskDTO';
import ModalComponent from '@/components/ModalComponent/ModalComponent.vue';
import { TasksService } from '@/services/TasksService';
import ModalCharging from '@/components/ModalChargingComponent/ModalCharging.vue';

@Options({
  name: 'TaskItem',
  // dynamic import to lazy load the task modal
  components: {
    ModalComponent,
    TaskModal: defineAsyncComponent(() => import('@/components/TaskModal/TaskModal.vue')),
    ModalCharging,
  },
  props: {
    task: {
      type: Object as () => Task,
      required: true,
    },
    isSubTask: {
      type: Boolean,
      default: false,
    },
    usersOnProject: {
      type: Object as () => User,
      required: true,
    },
  },
  data() {
    return {
      isCompleted: false,
      defaultImage: '',
      checkImage: '',
      newTask: {
        name: '',
        description: 'Descripción de la tarea',
        state: '0',
        parent_task_id: '',
      },
      showOnHover: false,
      openModal: false,
      loadModal: false,
      isLoading: false,
      // chargeDataTask: false,
    };
  },
  mounted() {
    this.asignImages();
  },
  computed: {
    checkedTask() {
      return this.task.state === '1' ? this.checkImage : this.defaultImage;
    },
  },
  methods: {
    asignImages() {
      /* eslint-disable */
      this.defaultImage = require('@/assets/icons/plus-icon.png');
      this.checkImage = require('@/assets/icons/check-icon.png');
      /* eslint-enable */
    },
    openTask() {
      this.openTaskModal();
    },
    toggleCompleted() {
      if (this.task.state === '0') {
        // eslint-disable-next-line no-restricted-globals
        const changeTaskState = confirm('Deseas cambiar el estado de la tarea a: "En progreso"?');
        this.task.state = '0.5';
        this.updateTask();
      } else if (this.task.state === '0.5') {
        this.task.state = '1';
        this.updateTask();
      } else if (this.task.state === '1') {
        this.task.state = '0.5';
        this.updateTask();
      }
    },
    updateTask() {
      const taskUpdateObj = {
        state: this.task.state === '' ? undefined : this.task.state,
      };
      this.sendUpdateTaskRequest(taskUpdateObj);
    },
    async sendUpdateTaskRequest(data: any) {
      try {
        this.isLoading = true;
        const response = await TasksService.updateTask(this.task.id, data);
        this.$emit('task-state-updated');
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert('Error al actualizar la tarea');
      }
    },
    addSubTask() {
      this.showOnHover = true;
    },
    createNewSubTask() {
      const newSubTask = new CreateTaskDTO({
        name: this.newTask.name,
        description: this.newTask.description,
        state: this.newTask.state,
        parent_task_id: this.task.id,
        deliver_date: this.task.deliverDate,
      });
      this.$emit('create-subtask', newSubTask);
      this.cancelAddTask();
    },
    cancelAddTask() {
      this.showOnHover = false;
      this.newTask.name = '';
      this.newTask.stage_id = '';
      this.newTask.parent_task_id = '';
    },
    openTaskModal(task: Task) {
      this.loadModal = true;
      this.openModal = true;
    },
    closeTaskModal() {
      this.openModal = false;
      this.loadModal = false;
    },
    emitTaskUpdated() {
      this.$emit('task-updated');
    },
    emitTaskDeleted() {
      this.closeTaskModal();
      this.$emit('task-deleted');
    },
  },
})

export default class TaskItem extends Vue {}

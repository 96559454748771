import { Options, Vue } from 'vue-class-component';

import HeaderApp from '@/components/HeaderApp/HeaderApp.vue';
import ModalComponent from '@/components/ModalComponent/ModalComponent.vue';
import { ProjectsService } from '@/services/ProjectsService';
import ProductDTO from '@/model/DTOs/ProductDTO';
import StageDTO from '@/model/DTOs/StageDTO';

@Options({
  name: 'PublicStageView',
  components: {
    HeaderApp,
    ModalComponent,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      documents: ['Documento 1', 'Documento 2', 'Documento 3', 'Documento 4'],
      product: 'gallery',
      projectData: null,
      productSelected: null,
      /* eslint-disable */
      moutainImage: require('@/assets/images/generic_mountain.png'),
      /* eslint-enable */
      isCompleted: true,
      openSurvey: false,
      surveyScore: 0,
      displayedName: '',
      displayedDescription: '',
      displayedInformation: '',
      productDocuments: [],
      products: [],
      firstProductPicked: '',
    };
  },
  mounted() {
    this.getAllProjectData();
  },
  methods: {
    async getAllProjectData() {
      try {
        const result = await ProjectsService.getFinalProjectDetailById(this.projectId);
        this.projectData = result.data;
        this.prepareProductData();
      } catch (error) {
        alert('Error al obtener los datos del producto');
      }
    },
    prepareProductData() {
      this.productSelected = this.projectData.products
        .find((product: ProductDTO) => product.product_id === this.productId);
      this.displayedName = this.productSelected.name;
      this.displayedDescription = this.productSelected.description;
      const lastStageOrder = this.productSelected.stages.length;
      const lastStage = this.productSelected.stages
        .find((stage: StageDTO) => stage.order === lastStageOrder);
      const documentsOnLastStage: any[] = [];
      lastStage.tasks.forEach((task: any) => {
        if (task.public) {
          task.documents.forEach((document: any) => {
            documentsOnLastStage.push(document);
          });
        }
      });
      this.productDocuments = documentsOnLastStage.map((document: any) => ({
        name: document.name,
        url: document.url_file,
      }));
    },
    getShareProductLink() {
      const stageLink = `${window.location.href}`;
      // Copy the text inside the text field
      navigator.clipboard.writeText(stageLink);
      // Alert the copied text
      alert(`Copiado el link: ${stageLink}`);
    },
  },
})

export default class PublicStageView extends Vue {}

import { Options, Vue } from 'vue-class-component';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import utils from '@/utils/utils';

@Options({
  name: 'UserOptionsModal',
  props: {
  },
  data() {
    return {
      isCompleted: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isUserAdmin', 'isUserProducer']),
  },
  methods: {
    ...mapActions('user', ['setInitialStateUser']),
    ...mapActions('applicationData', ['setInitialStateData']),
    closeModal() {
      this.$emit('close-modal');
    },
    openUserProfile() {
      this.$router.push({ name: 'profile' });
    },
    openUserCreation() {
      this.$router.push({ name: 'user-creation' });
    },
    openUserManagement() {
      this.$router.push({ name: 'user-management' });
    },
    openDataManagement() {
      this.$router.push({ name: 'management' });
    },
    logout() {
      this.setInitialStateUser();
      this.setInitialStateData();
      utils.removeTokenFromCookies();
      this.$router.push({ name: 'login' });
    },
    goHome() {
      this.$router.push({ name: 'projects' });
    },
  },
})

export default class UserOptionsModal extends Vue {}

import { Options, Vue } from 'vue-class-component';

import utils from '@/utils/utils';
import { UsersService } from '@/services/UsersService';

@Options({
  name: 'ResetPassword',
  data() {
    return {
      resetEmail: '',
    };
  },
  methods: {
    async requestPasswordReset() {
      try {
        const body = {
          email: this.resetEmail,
        };
        await UsersService.requestPasswordReset(body);
        alert('Se enviará un correo electronico para reestablecer su contraseña');
      } catch (error) {
        alert(`Ocurrió un error al hacer la petición: ${error}`);
      }
    },
  },
})

export default class ResetPassword extends Vue {}

import AuthDTO from '@/model/DTOs/AuthDTO';
import EnterpriseDTO from '@/model/DTOs/EnterpriseDTO';
import { User } from '@/model/Classes/User';
import { Project } from '@/model/Classes/Project';
import ProjectDTO from '@/model/DTOs/ProjectDTO';
import { ISendAuthDTO } from '@/model/DTOs/SendAuthDTO';
import { EnterprisesService } from '@/services/EnterprisesService';
import { UsersService } from '@/services/UsersService';
import utils from '@/utils/utils';

import { MUTATIONS } from '../../mutation-types';

const module = {
  namespaced: true,
  state() {
    return {
      userToken: undefined,
      loggedUser: undefined,
      userProfile: undefined,
      userEnterprise: undefined,
      userProjects: [],
      userProjectsTransformed: [],
      uniqueEnterprises: [],
    };
  },
  getters: {
    getUserProfileData(state: any) {
      return { type: state.userProfile.type, description: state.userProfile.description };
    },
    isUserAdmin(state: any) {
      return state.userProfile.type === 'Administrador';
    },
    isUserProducer(state: any) {
      return state.userProfile.type === 'Productor';
    },
    getHeaderName(state: any) {
      return utils.returnCapitalizedString(`${state.loggedUser.first_name}`);
    },
    getUrlProfileImage(state: any) {
      /* eslint-disable */
      return state.loggedUser.img_url ? state.loggedUser.img_url : require('@/assets/images/user.png');
      /* eslint-enable */
    },
    getUserAsUser(state: any) {
      return new User({
        id: state.loggedUser.user_id,
        name: state.loggedUser.first_name,
        imgUrl: state.loggedUser.img_url,
        headline: state.loggedUser.profesional_headline,
        profile: state.userProfile.type,
      });
    },
  },
  mutations: {
    [MUTATIONS.SET_USER_DATA](state: any, authDto: AuthDTO) {
      state.userToken = authDto.access_token;
      // document.cookie = `token=${state.userToken}`;
      utils.setTokenOnCookies(state.userToken);
      state.loggedUser = authDto.user;
      state.userProfile = authDto.user.profile;
      state.userEnterprise = authDto.user.enterprise;
      // TODO change this to Adminitrador when there's data
      state.userProjects = authDto.user.projects;
      const userEnterprise = [];
      userEnterprise.push(new EnterpriseDTO(authDto.user.enterprise));
      state.uniqueEnterprises = userEnterprise;

      if (state.userProjects.length > 0) {
        // process the projects
        state.userProjectsTransformed = state.userProjects
          .map((el: any) => new Project({
            id: el.project.project_id,
            name: el.project.name,
            description: el.project.description,
            state: el.project.state,
            imgUrl: el.project.img_url ? el.project.img_url : utils.returnRandomProjectImage(),
            enterpriseId: state.userEnterprise.enterprise_id,
            enterpriseName: state.userEnterprise.name,
            startDate: el.project.start_date ? el.project.start_date : undefined,
            endDate: el.project.end_date ? el.project.end_date : undefined,
            progress: el.project.progress ? el.project.progress : 0,
            category: el.project.category ? el.project.category.category_id : undefined,
          }));
      } else {
        state.userProjectsTransformed = [];
      }
    },
    [MUTATIONS.SET_ADMIN_DATA](state: any, authDto: AuthDTO) {
      state.userToken = authDto.access_token;
      // document.cookie = `token=${state.userToken}`;
      utils.setTokenOnCookies(state.userToken);
      state.loggedUser = authDto.user;
      state.userProfile = authDto.user.profile;
      state.userEnterprise = authDto.user.enterprise;
    },
    [MUTATIONS.PREPARE_ADMIN_PROJECTS](state: any, projectsByEnterprise: any) {
      state.userProjectsTransformed = [];
      projectsByEnterprise.forEach((enterprise: any) => {
        enterprise.projects.forEach((project: any) => {
          state.userProjectsTransformed.push(new Project({
            id: project.project_id,
            name: project.name,
            description: project.description,
            state: project.state,
            imgUrl: project.img_url ? project.img_url : utils.returnRandomProjectImage(),
            enterpriseId: enterprise.enterprise_id,
            enterpriseName: enterprise.name,
            startDate: project.start_date ? project.start_date : undefined,
            endDate: project.end_date ? project.end_date : undefined,
            progress: project.progress ? project.progress : 0,
            category: project.category ? project.category.category_id : undefined,
          }));
        });
      });
    },
    [MUTATIONS.PREPARE_ADMIN_ENTERPRISES](state: any, projectsByEnterprise: any) {
      state.uniqueEnterprises = projectsByEnterprise
        .map((enterprise: any) => new EnterpriseDTO(enterprise));
    },
    [MUTATIONS.SET_INITIAL_STATE_ON_USER](state: any) {
      state.userToken = undefined;
      state.loggedUser = undefined;
      state.userProfile = undefined;
      state.userEnterprise = undefined;
      state.userProjects = [];
      state.userProjectsTransformed = [];
      state.uniqueEnterprises = [];
    },
  },
  actions: {
    setInitialStateUser({ commit }: any) {
      commit(MUTATIONS.SET_INITIAL_STATE_ON_USER);
    },
    async sendLoginRequest({ commit }: any, bodyAuth: ISendAuthDTO) {
      try {
        const response = await UsersService.autenticateUser(bodyAuth);
        const authDto = new AuthDTO(response.data);
        if (authDto.user.profile?.type === 'Administrador') {
          commit(MUTATIONS.SET_ADMIN_DATA, authDto);
        } else {
          commit(MUTATIONS.SET_USER_DATA, authDto);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          alert('Usuario o contraseña incorrectos');
          throw new Error('Usuario o contraseña incorrectos');
        } else {
          alert(`Hubo un problema autenticando el usuario: ${error}`);
          throw new Error(`Hubo un problema autenticando el usuario: ${error}`);
        }
      }
    },
    async getAdminProjects({ commit }: any) {
      try {
        const response = await EnterprisesService.getEnterprises();
        commit(MUTATIONS.PREPARE_ADMIN_PROJECTS, response.data);
        commit(MUTATIONS.PREPARE_ADMIN_ENTERPRISES, response.data);
      } catch (error) {
        alert(`Hubo un problema obteniendo los proyectos, por favor ingrese nuevamente a la plataforma para ver los cambios: ${error}`);
        throw new Error(`Hubo un problema obteniendo los proyectos, por favor ingrese nuevamente a la plataforma para ver los cambios: ${error}`);
      }
    },
  },
};

export default module;

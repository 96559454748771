/* eslint-disable camelcase */
export interface ICreateTaskDTO {
  name: string
  description: string
  state: string
  stage_id?: string
  parent_task_id?: string
  deliver_date?: string
}

export default class CreateTaskDTO {
  public name: string;

  public description: string;

  public state: string;

  public stage_id?: string;

  public parent_task_id?: string;

  public deliver_date?: string;

  constructor(data: ICreateTaskDTO) {
    this.name = data.name;
    this.description = data.description;
    this.state = data.state;
    this.stage_id = data.stage_id;
    this.parent_task_id = data.parent_task_id;
    this.deliver_date = data.deliver_date;
  }
}

import { Options, Vue } from 'vue-class-component';
import { UsersService } from '@/services/UsersService';
import { mapGetters, mapState } from 'vuex';

import { User } from '@/model/Classes/User';
import UserDTO from '@/model/DTOs/UserDTO';
import ModalCharging from '../ModalChargingComponent/ModalCharging.vue';

@Options({
  name: 'UserForm',
  components: {
    ModalCharging,
  },
  props: {
    usedFor: {
      type: String,
      required: true,
      validator(value: string) {
        // The value must match one of these strings
        return ['view', 'edit', 'creation', 'updateProfile', 'management'].includes(value);
      },
    },
    userData: {
      type: Object as () => UserDTO,
      required: false,
    },
    userManagementActive: {
      type: Boolean,
      required: false,
    },
    userToManage: {
      type: Object as () => UserDTO,
      required: false,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      headline: '',
      profile: '',
      enterpriseUser: '',
      userImg: '',
      imgFile: undefined,
      updateUser: {
        firstName: '',
        lastName: '',
        headline: '',
        newImage: undefined,
        username: '',
        password: '',
        newPassword: '',
        confirmPassword: '',
      },
      userManaged: {
        profile: '',
        enterpriseUser: '',
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState('applicationData', ['activeProfiles', 'enterprises']),
    ...mapGetters('user', ['isUserAdmin']),
    ...mapState('user', ['loggedUser']),
  },
  methods: {
    cancelAdd() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.password = '';
      this.headline = '';
      this.profile = '';
      this.enterpriseUser = '';
      this.userImg = '';
      this.imgFile = undefined;
    },
    prepareFile(event: any) {
      const index = 0;
      this.imgFile = event.target.files[index];
    },
    createUser() {
      const newUser = new FormData();
      newUser.append('username', this.email);
      newUser.append('password', this.password);
      newUser.append('first_name', this.firstName);
      newUser.append('last_name', this.lastName);
      newUser.append('profesional_headline', this.headline);
      newUser.append('profile_id', this.profile);
      newUser.append('enterprise_id', this.enterpriseUser);
      newUser.append('img_url', this.imgFile);
      this.sendUserCreationRequest(newUser);
    },
    async sendUserCreationRequest(newUser: any) {
      try {
        this.isLoading = true;
        const userCreated = await UsersService.createUserEnterprise(newUser);
        alert('Usuario creado exitosamente');
        this.isLoading = false;
      } catch (error) {
        alert('Error creando el usuario');
        this.isLoading = false;
      }
    },
    async updateUserData() {
      try {
        this.isLoading = true;
        if (this.updateUser.firstName === '' && this.updateUser.lastName === '' && this.updateUser.headline === '') {
          alert('No se ha ingresado ningún dato para actualizar');
          this.isLoading = false;
        } else {
          const data = {
            first_name: this.updateUser.firstName ? this.updateUser.firstName : undefined,
            last_name: this.updateUser.lastName ? this.updateUser.lastName : undefined,
            profesional_headline: this.updateUser.headline ? this.updateUser.headline : undefined,
          };
          await UsersService.updateUser(this.loggedUser.user_id, data);
          this.isLoading = false;
          alert('Usuario actualizado');
        }
      } catch (error) {
        this.isLoading = false;
        alert(`Error actualizando el usuario: ${error}`);
      }
    },
    prepareFileUpdate(event: any) {
      const index = 0;
      this.updateUser.newImage = event.target.files[index];
      this.updateUserImage();
    },
    async updateUserImage() {
      try {
        this.isLoading = true;
        const newUserImage = new FormData();
        newUserImage.append('img_url', this.updateUser.newImage);
        await UsersService.updateUserImage(this.loggedUser.user_id, newUserImage);
        alert('Imagen actualizada, ingrese nuevamente para ver los cambios');
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert('Error guardando la imagen del proyecto');
      }
    },
    async updatePassword() {
      try {
        this.isLoading = true;
        const data = {
          username: this.updateUser.username ? this.updateUser.username : undefined,
          password: this.updateUser.password ? this.updateUser.password : undefined,
          new_password: this.updateUser.newPassword ? this.updateUser.newPassword : undefined,
          confirm_password: this.updateUser.confirmPassword
            ? this.updateUser.confirmPassword : undefined,
        };
        if (
          this.updateUser.username === ''
          || this.updateUser.password === ''
          || this.updateUser.newPassword === ''
          || this.updateUser.confirmPassword === ''
        ) {
          alert('Todos los campos para actualizar la contraseña son obligatorios');
        } else if (this.updateUser.newPassword !== this.updateUser.confirmPassword) {
          alert('La nueva contraseña y la confirmación de la misma no coinciden');
        } else {
          await UsersService.updateUserPassword(this.loggedUser.user_id, data);
          alert('Contraseña actualizada, ingrese nuevamente para validar el cambio si desea');
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert('Error actualizando la contraseña');
      }
    },
    async updateUserManagement() {
      try {
        this.isLoading = true;
        if (this.userManaged.profile === '' && this.userManaged.enterpriseUser === '') {
          alert('No se ha ingresado ningún dato para actualizar');
          this.isLoading = false;
        } else {
          const data = {
            profile_id: this.userManaged.profile ? this.userManaged.profile : undefined,
            enterprise_id: this.userManaged.enterpriseUser
              ? this.userManaged.enterpriseUser : undefined,
          };
          await UsersService.updateUser(this.userToManage.user_id, data);
          this.isLoading = false;
          alert('Usuario actualizado');
        }
      } catch (error) {
        this.isLoading = false;
        alert(`Error actualizando el usuario: ${error}`);
      }
    },
    cancelUserManagement() {
      this.$emit('cancel-user-management');
    },
  },
})
export default class UserForm extends Vue {}

/* eslint-disable class-methods-use-this */
import CreateEnterpriseDTO from '@/model/DTOs/CreateEnterpriseDTO';
import { APIService } from './ApiService';

class Service {
  public getEnterprises() {
    const apiPath = '/enterprise';
    const response = APIService.get(apiPath);
    return response;
  }

  public getEnterpriseById(id: string) {
    const apiPath = `/enterprise/${id}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public createEnterprise(body: CreateEnterpriseDTO) {
    const apiPath = '/enterprise';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public updateEnterprise(id: string, body: object) {
    const apiPath = `/enterprise/${id}`;
    const response = APIService.put(apiPath, body);
    return response;
  }

  public deleteEnterprise(id: string, body: object) {
    const apiPath = `/enterprise/${id}`;
    const response = APIService.delete(apiPath, body);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const EnterprisesService = new Service();

import ProjectDTO from './ProjectDTO';

/* eslint-disable camelcase */
export interface IEnterpriseDTO {
  enterprise_id: string;
  name: string;
  nit_id?: string;
  projects?: ProjectDTO[];
}

export default class EnterpriseDTO {
  public enterprise_id: string;

  public name: string;

  public nit_id?: string;

  public projects?: ProjectDTO[];

  constructor(data: IEnterpriseDTO) {
    this.enterprise_id = data.enterprise_id;
    this.name = data.name;
    this.nit_id = data.nit_id;
    this.projects = data.projects;
  }
}

import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Comment, CommentsArray } from '@/model/Classes/Comment';
import { Document, DocumentsArray } from '@/model/Classes/Document';
import ModalCharging from '@/components/ModalChargingComponent/ModalCharging.vue';
import Task from '@/model/Classes/Task';
import { User } from '@/model/Classes/User';
import { TasksService } from '@/services/TasksService';
import utils from '@/utils/utils';
import CreateCommentDTO from '@/model/DTOs/CreateCommentDTO';
import UserDTO from '@/model/DTOs/UserDTO';
import { CommentsService } from '@/services/CommentsService';
import { DocumentsService } from '@/services/DocumentsService';
import CommentItem from '../CommentItem/CommentItem.vue';
import UserProfilePicker from '../UserProfilePicker/UserProfilePicker.vue';

@Options({
  name: 'TaskModal',
  props: {
    task: {
      type: Object as () => Task,
      require: false,
    },
    chargeDataTask: {
      type: Boolean,
      default: false,
    },
    usersOnProject: {
      type: Object as () => User,
      required: true,
    },
  },
  components: {
    CommentItem,
    UserProfilePicker,
    ModalCharging,
  },
  data() {
    return {
      isCompleted: false,
      defaultImage: '',
      checkImage: '',
      /* eslint-disable */
      addIcon: require('@/assets/icons/plus-icon.png'),
      cancelIcon: require('@/assets/icons/plus-icon.png'),
      /* eslint-enable */
      documents: [],
      comments: [],
      commentData: {
        description: '',
      },
      taskData: {
        name: '',
        date: '',
        description: '',
        state: 0,
      },
      editSession: false,
      subTasksNumber: 0,
      newDocumentData: {
        file: null,
        name: '',
      },
      addingDocument: false,
      isTaskPublic: false,
      usersFromProject: [] as User[],
      usersOnTask: [] as User[],
      pickedUserToAdd: '',
      taskProjectUsersId: [],
      isLoading: false,
      previewTaskFolderId: '',
      previewUrlStageFolder: '',
    };
  },
  mounted() {
    this.getTaskData(this.task.id);
    this.taskData.name = this.task.name;
    this.taskData.date = this.task.deliverDate;
    this.taskData.description = this.task.description;
    this.taskData.state = this.task.state;
  },
  computed: {
    ...mapGetters('user', ['isUserAdmin', 'isUserProducer', 'getUserAsUser']),
  },
  methods: {
    // Document methods
    addDocument() {
      this.addingDocument = true;
    },
    cancelNewDocument() {
      this.newDocumentData.name = '';
      this.newDocumentData.file = null;
      this.addingDocument = false;
    },
    async sendDocumentCreationRequest(newDocument: any) {
      try {
        this.isLoading = true;
        const documentCreated = await DocumentsService.createDocumment(newDocument);
        this.cancelNewDocument();
        alert('Documento creado con éxito');
        this.getTaskData(this.task.id);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.cancelNewDocument();
        alert('Error creando el documento');
      }
    },
    prepareFile(event: any) {
      const index = 0;
      this.newDocumentData.file = event.target.files[index];
    },
    createDocument() {
      const newDocument = new FormData();
      newDocument.append('name', this.newDocumentData.name);
      newDocument.append('file', this.newDocumentData.file);
      newDocument.append('folder_stage_id', this.previewTaskFolderId);
      newDocument.append('task_id', this.task.id);
      newDocument.append('user_id', this.getUserAsUser.id);
      this.sendDocumentCreationRequest(newDocument);
    },
    deleteDocument(documentId: string) {
      // eslint-disable-next-line no-restricted-globals
      const confirmation = confirm('¿Está seguro de eliminar el documento?');
      if (confirmation) {
        this.sendDocumentDeleteRequest(documentId);
        this.getTaskData(this.task.id);
        alert('Documento eliminado con éxito');
      }
    },
    async sendDocumentDeleteRequest(documentId: string) {
      try {
        this.isLoading = true;
        const response = await DocumentsService.deleteById(documentId);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert('Error al eliminar el documento');
      }
    },
    // Comment methods
    updateComment($event: any) {
      this.updateCommentRequest($event.id, { description: $event.description });
    },
    async updateCommentRequest(commentId: string, body: any) {
      try {
        this.isLoading = true;
        const response = await CommentsService.updateComment(body, commentId);
        this.getTaskData(this.task.id);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert('Error al actualizar el comentario');
      }
    },
    async deleteComment($event: any) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('¿Está seguro de eliminar el comentario?')) {
        try {
          this.isLoading = true;
          const response = await CommentsService.deleteById($event.id);
          if (response.status === 200) {
            this.getTaskData(this.task.id);
          }
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          alert('Error al eliminar el comentario');
        }
      }
    },
    async saveComment() {
      try {
        this.isLoading = true;
        if (this.commentData.description) {
          // actual DTO to API
          const newCommentDTO = new CreateCommentDTO({
            description: this.commentData.description,
            date: utils.getCurrentDateUTCOnlyDate(),
            user_id: this.getUserAsUser.id,
            task_id: this.task.id,
          });
          const response = await CommentsService.createComment(newCommentDTO);
          this.commentData.description = '';
          this.getTaskData(this.task.id);
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error creando el comentario: ${error}`);
      }
    },
    transformIntoUserClass(data: UserDTO) {
      return new User({
        id: data.user_id,
        name: data.first_name,
        imgUrl: data.img_url,
        headline: data.profesional_headline,
        profile: data.profile?.type,
      });
    },
    transforIntoComment(data: any) {
      const newComment = new Comment({
        // change id, we have to get it from the API
        id: data.comment_id,
        description: data.description,
        date: data.date,
        owner: this.transformIntoUserClass(data.user), // get actual user
      });
      return newComment;
    },
    deleteNewComment() {
      this.commentData.description = '';
    },
    // general methods
    getStateName(state: string) {
      return utils.returnTaskStateVerbose(state);
    },
    activeEditSession() {
      this.editSession = true;
    },
    cancelEditSession() {
      this.editSession = false;
      this.taskData.name = '';
      this.taskData.date = '';
      this.taskData.description = '';
      this.taskData.state = '';
    },
    // main request that organize all the data
    async getTaskData(id: string) {
      try {
        this.isLoading = true;
        const response = await TasksService.getTaskDetail(id);
        this.subTasksNumber = response.data.child_tasks.length;
        this.isTaskPublic = response.data.public;
        this.previewTaskFolderId = response.data.folder_drive_id;
        this.previewUrlStageFolder = `https://drive.google.com/drive/folders/${this.previewTaskFolderId}`;
        this.comments = this.prepareComments(response.data);
        this.documents = this.prepareDocuments(response.data.documents);
        this.usersOnTask = this.prepareUsersOnTask(response.data.view_tasks);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error obteniendo la info del task: ${error}`);
      }
    },
    prepareComments(data: any) {
      const comments = data.comments.map((comment: any) => {
        const newComment = new Comment({
          id: comment.comment_id,
          description: comment.description,
          date: comment.date,
          owner: new User({
            id: comment.user.user_id,
            name: `${comment.user.first_name} ${comment.user.last_name}`,
            imgUrl: comment.user.img_url,
            profile: comment.user.profile.type,
            headline: comment.user.profesional_headline,
          }),
        });
        return newComment;
      });
      return comments;
    },
    prepareDocuments(documentsData: any) {
      const documents = documentsData.map((document: any) => {
        const newDocument = new Document({
          id: document.document_id,
          name: document.name,
          url: document.url_file,
          creationDate: document.created_at,
          owner: new User({
            id: document.user.user_id,
            name: `${document.user.first_name} ${document.user.last_name}`,
            imgUrl: document.user.img_url,
            profile: document.user.profile.type,
            headline: document.user.profesional_headline,
          }),
        });
        return newDocument;
      });
      return documents;
    },
    prepareUsersOnTask(viewtaskData: any) {
      const users = viewtaskData.map((viewTask: any) => {
        const userOnTask = new User({
          id: viewTask.user_project.user.user_id,
          name: viewTask.user_project.user.first_name,
          imgUrl: viewTask.user_project.user.img_url,
          headline: viewTask.user_project.user.profesional_headline,
          viewTaskId: viewTask.view_task_id,
        });
        return userOnTask;
      });
      return users;
    },
    updateTask() {
      const taskUpdateObj = {
        name: this.taskData.name === '' ? undefined : this.taskData.name,
        deliver_date: this.taskData.date === '' ? undefined : this.taskData.date,
        description: this.taskData.description === '' ? undefined : this.taskData.description,
        state: this.taskData.state === '' ? undefined : this.taskData.state,
      };
      this.sendUpdateTaskRequest(taskUpdateObj);
    },
    async sendUpdateTaskRequest(data: any) {
      try {
        this.isLoading = true;
        const response = await TasksService.updateTask(this.task.id, data);
        this.cancelEditSession();
        this.getTaskData(this.task.id);
        this.$emit('task-updated');
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert('Error al actualizar la tarea');
      }
    },
    formatDate(date: string) {
      return utils.formatUTCDate(date);
    },
    async makeTaskPrivate() {
      try {
        // eslint-disable-next-line no-restricted-globals
        const answer = confirm('¿Está seguro de hacer privada la tarea?');
        if (answer) {
          this.isLoading = true;
          await TasksService.updateTask(this.task.id, { public: false });
          this.isTaskPublic = false;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        alert('Error al hacer privada la tarea');
      }
    },
    async makeTaskPublic() {
      try {
        // eslint-disable-next-line no-restricted-globals
        const answer = confirm('¿Está seguro de hacer publica la tarea?');
        if (answer) {
          this.isLoading = true;
          await TasksService.updateTask(this.task.id, { public: true });
          this.isTaskPublic = true;
          this.isLoading = false;
        }
        // TODO make request to upload this state
      } catch (error) {
        this.isLoading = false;
        alert('Error al hacer publica la tarea');
      }
    },
    // Users permited to view the task
    addUserToTask() {
      try {
        const userAdded = this.usersOnProject
          .find((user: User) => user.id === this.pickedUserToAdd);
        const userIds = this.usersOnTask.map((user: User) => user.id);
        if (!userIds.includes(this.pickedUserToAdd)) {
          this.addUserToTaskOnEndpoint(userAdded);
          this.taskProjectUsersId.push({ user_project_id: userAdded.userProjectId });
          this.usersOnTask.push(userAdded);
        } else if (userIds.includes(this.pickedUserToAdd)) {
          alert('El usuario ya puede ver la tarea');
        }
      } catch (error) {
        alert(`Error al añadir el usuario a la tarea: ${error}`);
      }
    },
    removeUserFromTask(viewTaskId: string) {
      try {
        const index = this.usersOnTask.findIndex((user: User) => user.viewTaskId === viewTaskId);
        this.removeUserFromTaskOnEndpoint(viewTaskId);
        this.usersOnTask.splice(index, 1);
      } catch (error) {
        alert(`Error al eliminar el usuario de la tarea: ${error}`);
      }
    },
    async addUserToTaskOnEndpoint(user: User) {
      try {
        this.isLoading = true;
        const response = await TasksService.addUserToTask({
          user_project_id: user.userProjectId,
          task_id: this.task.id,
        });
        this.isLoading = false;
      } catch (error) {
        this.getTaskData(this.task.id);
        this.isLoading = false;
        alert(`Error al añadir el usuario a la tarea: ${error}`);
      }
    },
    async removeUserFromTaskOnEndpoint(viewTaskId: string) {
      try {
        this.isLoading = true;
        await TasksService.removeUserFromTask(viewTaskId);
        this.isLoading = false;
      } catch (error) {
        this.getTaskData(this.task.id);
        this.isLoading = false;
        alert(`Error al eliminar al usuario a la tarea: ${error}`);
      }
    },
    async deleteTask() {
      try {
        // eslint-disable-next-line no-restricted-globals
        const response = confirm('¿Está seguro de eliminar la tarea?');
        if (response) {
          TasksService.deleteById(this.task.id);
          this.$emit('deleted-task');
        }
      } catch (error) {
        alert('Error al eliminar la tarea');
      }
    },
    openDriveStageFolder() {
      window.open(this.previewUrlStageFolder);
    },
  },
})

export default class TaskModal extends Vue {}

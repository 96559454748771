import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a03dc0a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stagesContainer" }
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stages, (stage, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: stage.id,
        style: _normalizeStyle(_ctx.styleStage)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["stageTitle", {
          'span--grey': stage.state === 'todo',
          'span--green': stage.state === 'done',
          'span--yellow': stage.state === 'progress'
        }])
        }, _toDisplayString(stage.name), 3),
        _createElementVNode("button", {
          class: _normalizeClass([{
          'button--grey': stage.state === 'todo',
          'button--green': stage.state === 'done',
          'button--yellow': stage.state === 'progress'
        }, "pinBubble"]),
          id: `buttonStage_` + stage.id,
          onClick: ($event: any) => (_ctx.pickStage(stage.id))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["material-symbols-outlined", {
            'span--grey': stage.state === 'todo',
            'span--green': stage.state === 'done',
            'span--yellow': stage.state === 'progress'
          }])
          }, " share_location ", 2)
        ], 10, _hoisted_2),
        _createElementVNode("img", {
          class: "mountainImg",
          src: stage.imgUrl,
          alt: ""
        }, null, 8, _hoisted_3),
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.styleComplements[index])
        }, null, 4)
      ], 4))
    }), 128))
  ]))
}
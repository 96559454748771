export default class Contact {
  public contactEmail?: string

  public contactPhone?: string

  public contactMeetingUrl?: string

  constructor(data: Contact) {
    this.contactEmail = data.contactEmail;
    this.contactPhone = data.contactPhone;
    this.contactMeetingUrl = data.contactMeetingUrl;
  }
}

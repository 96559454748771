import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LogInView from '@/views/LogInView/LogInView.vue';
import ProjectGallery from '@/views/ProjectGalleryView/ProjectGallery.vue';
import ProductView from '@/views/ProductView/ProductView.vue';
import LastStageView from '@/views/LastStageView/LastStageView.vue';
import UserEnterpriseProfile from '@/views/UserEnterpriseProfile/UserEnterpriseProfile.vue';
import UserManagement from '@/views/UserManagement/UserManagement.vue';
import UserProfile from '@/views/UserProfile/UserProfile.vue';
import PublicStageView from '@/views/PublicStageView/PublicStageView.vue';
import DataManagement from '@/views/DataManagement/DataManagement.vue';
import ResetPasswordView from '@/views/ResetPasswordView/ResetPasswordView.vue';

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
  {
    path: '/',
    name: 'login',
    component: LogInView,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectGallery,
  },
  {
    path: '/products/:projectId',
    name: 'products',
    component: ProductView,
    props: true,
  },
  {
    path: '/last-stage/:projectId/:productId',
    name: 'last-stage',
    component: LastStageView,
    props: true,
  },
  {
    path: '/profile',
    name: 'profile',
    component: UserProfile,
  },
  {
    path: '/user-creation',
    name: 'user-creation',
    component: UserEnterpriseProfile,
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: UserManagement,
  },
  {
    path: '/public-stage/:projectId/:productId',
    name: 'public-stage',
    component: PublicStageView,
    props: true,
  },
  {
    path: '/reset-password/:resetEmail/:confirmationToken',
    name: 'reset-password',
    component: ResetPasswordView,
    props: true,
  },
  {
    path: '/data-management',
    name: 'management',
    component: DataManagement,
  },
  /**
   * Undefined routes get redirected to a 404.
   */
  // {
  //   path: '/:catchAll(.*)',
  //   component: PageNotFound,
  //   name: 'NotFound'
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

/* eslint-disable import/prefer-default-export */
const SET_USER_DATA = 'setUserData';
const SET_ADMIN_DATA = 'setAdminData';
const SET_ACTIVE_PROFILES = 'setActiveProfiles';
const SET_REGISTERED_ENTERPRISES = 'setRegisteredEnterprises';
const ADD_NEW_ENTERPRISE_DATA = 'addNewEnterpriseData';
const SET_EDITING_PROJECT_STATE = 'setEditingProjectState';
const SET_REGISTERED_CATEGORIES = 'setRegisteredCategories';
const ADD_NEW_PROJECT = 'addNewProject';
const SET_USERS_FROM_ENTERPRISE = 'setUsersFromEnterprise';
const PREPARE_ADMIN_PROJECTS = 'prepareAdminProjects';
const SET_INITIAL_STATE_ON_USER = 'setInitialStateOnUser';
const SET_INITIAL_STATE_ON_DATA = 'setInitialStateOnData';
const PREPARE_ADMIN_ENTERPRISES = 'prepareAdminEnterprises';

export const MUTATIONS = {
  SET_USER_DATA,
  SET_ADMIN_DATA,
  SET_ACTIVE_PROFILES,
  SET_REGISTERED_ENTERPRISES,
  ADD_NEW_ENTERPRISE_DATA,
  SET_EDITING_PROJECT_STATE,
  SET_REGISTERED_CATEGORIES,
  ADD_NEW_PROJECT,
  SET_USERS_FROM_ENTERPRISE,
  PREPARE_ADMIN_PROJECTS,
  SET_INITIAL_STATE_ON_USER,
  SET_INITIAL_STATE_ON_DATA,
  PREPARE_ADMIN_ENTERPRISES,
};

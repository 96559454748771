import CreateStageDTO from './CreateStageDTO';
/* eslint-disable camelcase */
export interface ICreateProductDTO {
  name: string;
  description: string;
  start_date?: string;
  end_date?: string;
  state: string;
  project_id?: string;
  stages?: CreateStageDTO[];
}

export default class CreateProductDTO {
  public name: string;

  public description: string;

  public start_date?: string;

  public end_date?: string;

  public state: string;

  public project_id?: string;

  public stages?: CreateStageDTO[];

  constructor(data: ICreateProductDTO) {
    this.name = data.name;
    this.description = data.description;
    this.start_date = data.start_date;
    this.end_date = data.end_date;
    this.state = data.state;
    this.project_id = data.project_id;
    this.stages = data.stages;
  }
}

import { Options, Vue } from 'vue-class-component';
import UserForm from '@/components/UserForm/UserForm.vue';
import HeaderApp from '@/components/HeaderApp/HeaderApp.vue';
import EnterpriseForm from '@/components/EnterpriseForm/EnterpriseForm.vue';

@Options({
  name: 'UserProfile',
  components: {
    EnterpriseForm,
    HeaderApp,
    UserForm,
  },
  data() {
    return {
      username: '',
      /* eslint-disable */
      userImg: require('@/assets/images/user.png'),
      /* eslint-enable */
    };
  },
  methods: {
  },
})
export default class UserProfile extends Vue {}

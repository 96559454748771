import { Options, Vue } from 'vue-class-component';

import { Comment } from '@/model/Classes/Comment';
import { mapState } from 'vuex';
import utils from '@/utils/utils';

@Options({
  name: 'CommentItem',
  props: {
    comment: {
      type: Object as () => Comment,
      required: true,
    },
  },
  data() {
    return {
      /* eslint-disable */
      addIcon: require('@/assets/icons/plus-icon.png'),
      /* eslint-enable */
      state: 'default',
      data: {
        // Property 'description' does not exist on type 'never'.
        description: (this.comment as Comment).description,
      },
    };
  },
  computed: {
    ...mapState('user', ['loggedUser']),
    editable() {
      if (this.loggedUser.profile.type === 'Administrador') {
        return true;
      }
      return this.loggedUser.user_id === this.comment.owner.id;
    },
  },
  methods: {
    resetEdit() {
      this.state = 'default';
      this.data.description = this.comment.description;
    },
    saveEdit() {
      this.state = 'default';
      this.$emit('comment-updated', {
        id: this.comment.id,
        description: this.data.description,
      });
      // TODO add update comment
    },
    deleteComment() {
      this.$emit('comment-deleted', {
        id: this.comment.id,
      });
    },
    formatName(name: string) {
      return utils.returnCapitalizedString(name);
    },
  },
})

export default class CommentItem extends Vue {}

/* eslint-disable class-methods-use-this */
import CreateTaskDTO from '@/model/DTOs/CreateTaskDTO';
import { APIService } from './ApiService';

class Service {
  public createTask(body: CreateTaskDTO) {
    const apiPath = '/task';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public getTaskDetail(id: string) {
    const apiPath = `/task/${id}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public getCompleteTaskDetail(id: string) {
    const apiPath = `/viewtask/${id}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public updateTask(id: string, body: object) {
    const apiPath = `/task/${id}`;
    const response = APIService.put(apiPath, body);
    return response;
  }

  public deleteById(id: string) {
    const apiPath = `/task/${id}`;
    const response = APIService.delete(apiPath);
    return response;
  }

  public addUserToTask(body: object) {
    const apiPath = '/viewtask';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public removeUserFromTask(id: string) {
    const apiPath = `/viewtask/${id}`;
    const response = APIService.delete(apiPath);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const TasksService = new Service();

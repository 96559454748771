import { User } from './User';

export class Comment {
  public id?: string

  public description?: string

  public date?: string

  public owner?: User

  constructor(data: Comment) {
    this.id = data.id;
    this.description = data.description;
    this.date = data.date;
    this.owner = data.owner;
  }
}

export const CommentsArray = [
  new Comment({
    id: 'testid',
    description: 'Comment 1',
    owner: new User({
      id: 'test',
      name: 'User 1',
      imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
      profile: 'Profile text',
      headline: 'Headline Text',
    }),
    date: '10-20-2023',
  }),
  new Comment({
    id: 'testid',
    description: 'Comment 2 shorter',
    owner: new User({
      id: 'test',
      name: 'User 1',
      imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
      profile: 'Profile text',
      headline: 'Headline Text',
    }),
    date: '10-20-2023',
  }),
  new Comment({
    id: 'testid',
    description: 'Comment 3',
    owner: new User({
      id: 'test',
      name: 'User 1',
      imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
      profile: 'Profile text',
      headline: 'Headline Text',
    }),
    date: '10-20-2023',
  }),
  new Comment({
    id: 'testid',
    description: 'Comment 3',
    owner: new User({
      id: 'test',
      name: 'User 1',
      imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
      profile: 'Profile text',
      headline: 'Headline Text',
    }),
    date: '10-20-2023',
  }),
  new Comment({
    id: 'testid',
    description: 'Comment 3',
    owner: new User({
      id: 'test',
      name: 'User 1',
      imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
      profile: 'Profile text',
      headline: 'Headline Text',
    }),
    date: '10-20-2023',
  }),
  new Comment({
    id: 'testid',
    description: 'Comment 3',
    owner: new User({
      id: 'test',
      name: 'User 1',
      imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
      profile: 'Profile text',
      headline: 'Headline Text',
    }),
    date: '10-20-2023',
  }),
  new Comment({
    id: 'testid',
    description: 'Comment 4 lon description',
    owner: new User({
      id: 'test',
      name: 'User 1',
      imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
      profile: 'Profile text',
      headline: 'Headline Text',
    }),
    date: '10-20-2023',
  }),
];

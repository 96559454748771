import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dda78b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cardContainer" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "cardContainer__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalCharging = _resolveComponent("ModalCharging")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalCharging, { open: _ctx.isLoading }, null, 8, ["open"]),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pickProject && _ctx.pickProject(...args)))
    }, [
      _createElementVNode("img", {
        class: _normalizeClass({ cardContainer__image: true, newProyectCard: _ctx.addingCard }),
        src: _ctx.addingCard ? _ctx.addCard.imgUrl : _ctx.cardProject.imgUrl,
        alt: "project image"
      }, null, 10, _hoisted_2)
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.addingCard ? _ctx.addCard.name : _ctx.cardProject.name), 1),
    _createElementVNode("div", null, [
      (_ctx.isUserAdmin)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "material-symbols-outlined deleteButton",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deleteProject && _ctx.deleteProject(...args)))
          }, "cancel"))
        : _createCommentVNode("", true)
    ])
  ]))
}
/* eslint-disable class-methods-use-this */
import CreateCommentDTO from '@/model/DTOs/CreateCommentDTO';
import { APIService } from './ApiService';

class Service {
  public createComment(body: CreateCommentDTO) {
    const apiPath = '/comment';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public getCommentById(id: string) {
    const apiPath = `/comment/${id}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public updateComment(body: object, id: string) {
    const apiPath = `/comment/${id}`;
    const response = APIService.put(apiPath, body);
    return response;
  }

  public deleteById(id: string) {
    const apiPath = `/comment/${id}`;
    const response = APIService.delete(apiPath);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const CommentsService = new Service();

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3903da82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "generalContainer" }
const _hoisted_2 = { class: "galleryContainer" }
const _hoisted_3 = { class: "galleryContainer__cards" }
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderApp = _resolveComponent("HeaderApp")!
  const _component_GalleryCard = _resolveComponent("GalleryCard")!
  const _component_GalleryCardDescription = _resolveComponent("GalleryCardDescription")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HeaderApp, {
        onChangeEnterpriseFilter: _ctx.changeFilterEnterprise,
        onChangeStateFilter: _ctx.changeFilterState,
        onChangeCategoryFilter: _ctx.changeFilterCategory
      }, null, 8, ["onChangeEnterpriseFilter", "onChangeStateFilter", "onChangeCategoryFilter"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleProjects, (project) => {
          return (_openBlock(), _createElementBlock("div", {
            key: project.id
          }, [
            _createVNode(_Transition, null, {
              default: _withCtx(() => [
                _createVNode(_component_GalleryCard, {
                  "card-project": project,
                  onPickProject: _ctx.changeProjectPicked,
                  onDeletedProject: _ctx.deleteProject
                }, null, 8, ["card-project", "onPickProject", "onDeletedProject"])
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128)),
        _createElementVNode("div", null, [
          _createVNode(_Transition, null, {
            default: _withCtx(() => [
              (_ctx.isUserAdmin || _ctx.isUserProducer)
                ? (_openBlock(), _createBlock(_component_GalleryCard, {
                    key: 0,
                    "adding-card": "",
                    onPickProject: _ctx.createProject
                  }, null, 8, ["onPickProject"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_GalleryCardDescription, {
            "project-picked": _ctx.projectPicked,
            "creating-project": _ctx.creatingProject,
            onCloseDescriptionContainer: _ctx.closeDescriptionContainer,
            onProjectCreated: _ctx.refreshProjects,
            onProjectUpdated: _ctx.refreshProjects
          }, null, 8, ["project-picked", "creating-project", "onCloseDescriptionContainer", "onProjectCreated", "onProjectUpdated"])
        ], 512), [
          [_vShow, _ctx.isProjectPicked]
        ])
      ]),
      _: 1
    })
  ]))
}
import { Options, Vue } from 'vue-class-component';
import SendAuthDTO from '@/model/DTOs/SendAuthDTO';
import {
  mapState, mapMutations, mapActions,
} from 'vuex';
import ModalCharging from '@/components/ModalChargingComponent/ModalCharging.vue';
import ModalComponent from '@/components/ModalComponent/ModalComponent.vue';
import ResetPassword from '@/components/ResetPassword/ResetPassword.vue';

@Options({
  name: 'LogInView',
  components: {
    ModalCharging,
    ModalComponent,
    ResetPassword,
  },
  data() {
    return {
      username: '',
      password: '',
      /* eslint-disable */
      userImg: require('@/assets/images/cordada.png'),
      /* eslint-enable */
      openChargeModal: false,
      openResetModal: false,
    };
  },
  mounted() {
    this.getActiveProfilesFromService();
    this.getRegisteredEnterprisesFromService();
    this.getRegisteredCategoriesFromService();
  },
  computed: {
    ...mapState('user', ['userToken', 'loggedUser']),
    ...mapState('applicationData', ['activeProfiles']),
  },
  methods: {
    ...mapMutations('user', ['setUserData']),
    ...mapActions('user', ['sendLoginRequest']),
    ...mapActions('applicationData', ['getActiveProfiles', 'getRegisteredEnterprises', 'getRegisteredCategories']),
    async authenticateUser() {
      try {
        this.openChargeModal = true;
        const bodyAuth = new SendAuthDTO({ username: this.username, password: this.password });
        await this.sendLoginRequest(bodyAuth);
        if (this.userToken !== '' && this.loggedUser !== '') {
          this.openChargeModal = false;
          this.$router.push({ name: 'projects' });
        }
      } catch (error) {
        this.openChargeModal = false;
        alert(`Error al autenticar usuario: ${error}`);
      }
    },
    async getActiveProfilesFromService() {
      try {
        await this.getActiveProfiles();
      } catch (error) {
        alert('Error al obtener los perfiles activos');
      }
    },
    async getRegisteredEnterprisesFromService() {
      try {
        await this.getRegisteredEnterprises();
      } catch (error) {
        alert('Error al obtener las empresas registradas');
      }
    },
    async getRegisteredCategoriesFromService() {
      try {
        await this.getRegisteredCategories();
      } catch (error) {
        alert('Error al obtener las categorias registradas');
      }
    },
    openResetPassword() {
      this.openResetModal = true;
    },
    closeResetModal() {
      this.openResetModal = false;
    },
  },
})
export default class LogInView extends Vue {}

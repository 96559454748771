export class User {
  public id: string

  public name?: string

  public imgUrl?: string

  public profile?: string

  public headline?: string

  public userProjectId?: string

  public viewTaskId?: string

  constructor(data: User) {
    this.id = data.id;
    this.name = data.name;
    this.imgUrl = data.imgUrl;
    this.profile = data.profile;
    this.headline = data.headline;
    this.userProjectId = data.userProjectId;
    this.viewTaskId = data.viewTaskId;
  }
}

export const UsersArray = [
  new User({
    id: 'test',
    name: 'Andres Loto',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
    profile: 'Administrador',
    headline: 'Developer',
  }),
  new User({
    id: 'test',
    name: 'Cristian',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
    profile: 'Administrador',
    headline: 'Manager',
  }),
  new User({
    id: 'test',
    name: 'Juan',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
    profile: 'Manager',
    headline: 'Designer',
  }),
  new User({
    id: 'test',
    name: 'Alejandro',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
    profile: 'Manager',
    headline: 'Developer',
  }),
  new User({
    id: 'test',
    name: 'Usuario prueba',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-User-management-body-of-knowledge-User-management-professional-User-manager-management-User-miscellaneous-text-thumbnail.png',
    profile: 'Proveedor',
    headline: 'Designer',
  }),
];

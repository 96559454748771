import CategoryDTO from '@/model/DTOs/CategoryDTO';
import EnterpriseDTO from '@/model/DTOs/EnterpriseDTO';
import ProfileDTO from '@/model/DTOs/ProfileDTO';
import UserDTO from '@/model/DTOs/UserDTO';

import { CategoriesService } from '@/services/CategoriesService';
import { EnterprisesService } from '@/services/EnterprisesService';
import { ProfilesService } from '@/services/ProfilesService';

import { MUTATIONS } from '../../mutation-types';

const module = {
  namespaced: true,
  state() {
    return {
      activeProfiles: [],
      enterprises: [],
      categories: [],
      usersFromEnterprise: [],
    };
  },
  getters: {
  },
  mutations: {
    [MUTATIONS.SET_ACTIVE_PROFILES](state: any, profiles: ProfileDTO[]) {
      state.activeProfiles = profiles;
    },
    [MUTATIONS.SET_REGISTERED_ENTERPRISES](state: any, enterprises: EnterpriseDTO[]) {
      state.enterprises = enterprises;
    },
    [MUTATIONS.ADD_NEW_ENTERPRISE_DATA](state: any, newEnterprise: EnterpriseDTO) {
      state.enterprises.push(newEnterprise);
    },
    [MUTATIONS.SET_REGISTERED_CATEGORIES](state: any, categories: CategoryDTO[]) {
      state.categories = categories;
    },
    [MUTATIONS.SET_USERS_FROM_ENTERPRISE](state: any, users: UserDTO[]) {
      state.usersFromEnterprise = users;
    },
    [MUTATIONS.SET_INITIAL_STATE_ON_DATA](state: any) {
      state.activeProfiles = [];
      state.enterprises = [];
      state.categories = [];
      state.usersFromEnterprise = [];
    },
  },
  actions: {
    setInitialStateData({ commit }: any) {
      commit(MUTATIONS.SET_INITIAL_STATE_ON_DATA);
    },
    async getActiveProfiles({ commit }: any) {
      try {
        const response = await ProfilesService.getProfiles();
        commit(MUTATIONS.SET_ACTIVE_PROFILES, response.data);
      } catch (error) {
        alert(`Hubo un problema obteniendo los perfiles de usuario: ${error}`);
      }
    },
    async getRegisteredEnterprises({ commit }: any) {
      try {
        const response = await EnterprisesService.getEnterprises();
        commit(MUTATIONS.SET_REGISTERED_ENTERPRISES, response.data);
      } catch (error) {
        alert(`Hubo un problema obteniendo las empresas registradas: ${error}`);
      }
    },
    async getRegisteredCategories({ commit }: any) {
      try {
        const response = await CategoriesService.getCategories();
        commit(MUTATIONS.SET_REGISTERED_CATEGORIES, response.data);
      } catch (error) {
        alert(`Hubo un problema obteniendo las categorias registradas: ${error}`);
      }
    },
    async getUsersFromEnterprise({ commit }: any, enterpriseId: string) {
      try {
        const response = await EnterprisesService.getEnterpriseById(enterpriseId);
        commit(MUTATIONS.SET_USERS_FROM_ENTERPRISE, response.data.users);
      } catch (error) {
        alert(`Hubo un problema obteniendo las usuarios de la empresa: ${error}`);
      }
    },
  },
};

export default module;

export default class ViewTask {
  public viewTaskId?: string

  public userProjectId?: string

  public userId?: string

  constructor(data: ViewTask) {
    this.viewTaskId = data.viewTaskId;
    this.userProjectId = data.userProjectId;
    this.userId = data.userId;
  }
}

import utils from '@/utils/utils';
import CreateProductDTO from './CreateProductDTO';
import { CreateProjectDTO } from './CreateProjectDTO';
import CreateStageDTO from './CreateStageDTO';

/* eslint-disable camelcase */

export interface IUserId {
  user_id: string;
}

export interface ICreateBaseProjectDTO {
  project: CreateProjectDTO;
  products: CreateProductDTO[];
  stages: CreateStageDTO[];
  users: IUserId[];
}

export class CreateBaseProjectDTO {
  public project: CreateProjectDTO;

  public products: CreateProductDTO[];

  public stages: CreateStageDTO[];

  public users: IUserId[];

  constructor(data: ICreateBaseProjectDTO) {
    this.project = data.project;
    this.products = data.products;
    this.stages = data.stages;
    this.users = data.users;
  }
}

export const BaseProjectDTO = new CreateBaseProjectDTO({
  project: new CreateProjectDTO({
    name: 'Proyecto Base',
    description: 'Descripción del proyecto base',
    start_date: utils.getCurrentDateUTCOnlyDate(),
    state: 'todo',
    category_id: '',
    enterprise_id: '',
  }),
  products: [
    new CreateProductDTO({
      name: 'Producto 1',
      description: 'Descripción del producto 1',
      state: 'todo',
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
  ],
  stages: [
    new CreateStageDTO({
      name: 'Planeación',
      state: 'progress',
      description: 'Planeación del producto',
      progress: 1,
      order: 1,
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
    new CreateStageDTO({
      name: 'Pre-producción',
      state: 'todo',
      description: 'Pre-producción del producto',
      progress: 1,
      order: 2,
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
    new CreateStageDTO({
      name: 'Producción',
      state: 'todo',
      description: 'Producción del producto',
      progress: 1,
      order: 3,
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
    new CreateStageDTO({
      name: 'Entrega',
      state: 'todo',
      description: 'Entrega del producto',
      progress: 1,
      order: 4,
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
  ],
  users: [],
});

import { Options, Vue } from 'vue-class-component';

import UserForm from '@/components/UserForm/UserForm.vue';
import HeaderApp from '@/components/HeaderApp/HeaderApp.vue';
import EnterpriseForm from '@/components/EnterpriseForm/EnterpriseForm.vue';

@Options({
  name: 'UserEnterpriseProfile',
  components: {
    EnterpriseForm,
    HeaderApp,
    UserForm,
  },
  data() {
    return {
    };
  },
  methods: {
  },
})
export default class UserEnterpriseProfile extends Vue {}

export class Project {
  public id?: string

  public name?: string

  public description?: string

  public progress?: number

  public startDate?: string

  public endDate?: string

  public imgUrl?: string

  public users?: string[]

  public products?: string[]

  public state?: string

  public enterpriseId?: string

  public enterpriseName?: string

  public category?: string

  constructor(data: Project) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.progress = data.progress;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.imgUrl = data.imgUrl;
    this.users = data.users;
    this.products = data.products;
    this.state = data.state;
    this.enterpriseId = data.enterpriseId;
    this.enterpriseName = data.enterpriseName;
    this.category = data.category;
  }
}

export const ProjectsArray = [
  new Project({
    id: '',
    name: 'Project 1',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-project-management-body-of-knowledge-project-management-professional-project-manager-management-project-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Project({
    id: '',
    name: 'Project 2 shorter',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-project-management-body-of-knowledge-project-management-professional-project-manager-management-project-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Project({
    id: '',
    name: 'Project 3',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-project-management-body-of-knowledge-project-management-professional-project-manager-management-project-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Project({
    id: '',
    name: 'Project 3',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-project-management-body-of-knowledge-project-management-professional-project-manager-management-project-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Project({
    id: '',
    name: 'Project 3',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-project-management-body-of-knowledge-project-management-professional-project-manager-management-project-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Project({
    id: '',
    name: 'Project 3',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-project-management-body-of-knowledge-project-management-professional-project-manager-management-project-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Project({
    id: '',
    name: 'Project 4 lon name',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-project-management-body-of-knowledge-project-management-professional-project-manager-management-project-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
];

import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { CategoriesService } from '@/services/CategoriesService';

import HeaderApp from '@/components/HeaderApp/HeaderApp.vue';

@Options({
  name: 'DataManagement',
  components: {
    HeaderApp,
  },
  data() {
    return {
      categoryId: null,
    };
  },
  computed: {
    ...mapState('applicationData', ['categories']),
  },
  methods: {
    ...mapActions('applicationData', ['getRegisteredCategories']),
    async sendDeleteRequest() {
      try {
        const response = await CategoriesService.deleteCategory(this.categoryId);
        this.getRegisteredCategories();
      } catch (error) {
        alert(`Error creando nueva empresa: ${error}`);
      }
    },
  },
})
export default class DataManagement extends Vue {}

import UserDTO from './UserDTO';

/* eslint-disable camelcase */
export interface IAuthDTO {
  access_token: string;
  user: UserDTO;
}

export default class AuthDTO {
  public access_token: string;

  public user: UserDTO;

  constructor(data: IAuthDTO) {
    this.access_token = data.access_token;
    this.user = data.user;
  }
}

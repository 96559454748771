/* eslint-disable camelcase */
export interface ICreateEnterpriseDTO {
  name: string;
  nit_id: string;
}

export default class CreateEnterpriseDTO {
  public name: string;

  public nit_id: string;

  constructor(data: ICreateEnterpriseDTO) {
    this.name = data.name;
    this.nit_id = data.nit_id;
  }
}

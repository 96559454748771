import { Options, Vue } from 'vue-class-component';

import { Stage, StagesArray } from '@/model/Classes/Stage';

@Options({
  name: 'ProductStages',
  props: {
    stages: {
      type: Object as () => Stage[],
      required: true,
    },
  },
  data() {
    return {
      name: '',
      imgUrl: '',
      stagesTest: [],
      stagesProcessed: [],
      styleStage: {},
      styleComplements: [],
      zIndexCounter: 0,
      stageWidth: 1,
    };
  },
  watch: {
    stages(newVal) {
      this.calculateStageStyles();
      this.prepareStage();
    },
  },
  methods: {
    prepareStage() {
      this.styleComplements = [];
      this.stagesProcessed = this.stages.map((stage: Stage, index: number) => {
        // set zindex MAYBE ITS NOT NECESSARY
        /* eslint-disable */
        stage.stageIndex = index + 1;
        stage.stageElevation = index > 0 ? 10 * index/2 : index;
        this.styleComplements.push({
          height: `${stage.stageElevation}%`,
          'background-color': '#25654b',
          width: '100%',
        });
        //set pin image
        switch (index) {
          case 0:
            // stage.urlPinImg = require('@/assets/icons/start-pin.png');
            stage.imgUrl = require('@/assets/images/start_mountain.png');
            break;
          case this.stagesTest.length-1:
            // stage.urlPinImg = require('@/assets/icons/end-pin.png');
            stage.imgUrl = require('@/assets/images/generic_mountain.png');
            break;
          default:
            // stage.urlPinImg = require('@/assets/icons/average-pin.png');
            stage.imgUrl = require('@/assets/images/generic_mountain.png');
            break;
        }
        /* eslint-enable */
        return stage;
      });
    },
    calculateStageStyles() {
      this.stageWidth = 100 / this.stages.length;
      this.styleStage = {
        width: `${this.stageWidth}%`,
        // border: '1px solid red',
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'flex-end',
        'background-color': '#b8dae9',
        'padding-top': '1em',
      };
    },
    pickStage(stageId: string) {
      // TODO verify this method to get the last stage
      const stagePicked = this.stages.find((el: Stage) => el.id === stageId);
      if (stagePicked.stageIndex === this.stages.length) {
        // this.$router.push({ name: 'last-stage' });
        this.$emit('last-stage-picked', stagePicked);
      } else {
        this.$emit('stage-picked', stagePicked);
      }
    },
  },
})

export default class ProductStages extends Vue {}

import utils from '@/utils/utils';
import CreateProductDTO from './CreateProductDTO';
import CreateStageDTO from './CreateStageDTO';

/* eslint-disable camelcase */
export interface ICreateBaseProductDTO {
  product: CreateProductDTO;
  stages: CreateStageDTO[];
}

export class CreateBaseProductDTO {
  public product: CreateProductDTO;

  public stages: CreateStageDTO[];

  constructor(data: ICreateBaseProductDTO) {
    this.product = data.product;
    this.stages = data.stages;
  }
}

export const BaseProductDTO = new CreateBaseProductDTO({
  product: new CreateProductDTO({
    name: 'Producto NN',
    description: 'Descripción del producto',
    state: 'todo',
    start_date: utils.getCurrentDateUTCOnlyDate(),
    project_id: '',
  }),
  stages: [
    new CreateStageDTO({
      name: 'Planeación',
      state: 'progress',
      description: 'Planeación del producto',
      progress: 1,
      order: 1,
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
    new CreateStageDTO({
      name: 'Pre-producción',
      state: 'todo',
      description: 'Pre-producción del producto',
      progress: 1,
      order: 2,
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
    new CreateStageDTO({
      name: 'Producción',
      state: 'todo',
      description: 'Producción del producto',
      progress: 1,
      order: 3,
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
    new CreateStageDTO({
      name: 'Entrega',
      state: 'todo',
      description: 'Entrega del producto',
      progress: 1,
      order: 4,
      start_date: utils.getCurrentDateUTCOnlyDate(),
    }),
  ],
});

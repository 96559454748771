import { Options, Vue } from 'vue-class-component';

import { User } from '@/model/Classes/User';
import utils from '@/utils/utils';

@Options({
  name: 'UserProfilePicker',
  props: {
    user: {
      type: Object as () => User,
      required: false,
    },
    editing: {
      type: Boolean,
      required: true,
    },
    miniVersion: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    removeUserFrom() {
      this.$emit('remove-user-from', this.user.id);
    },
    formatName(name: string) {
      return utils.returnCapitalizedString(name);
    },
  },
})

export default class UserProfilePicker extends Vue {}

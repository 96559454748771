/* eslint-disable class-methods-use-this */
import { CreateBaseProjectDTO } from '@/model/DTOs/CreateBaseProjectDTO';
import { APIService } from './ApiService';
import { APIDocumentsService } from './ApiDocumentsService';

class Service {
  public createBaseProject(body: CreateBaseProjectDTO) {
    const apiPath = '/project/components';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public updateProject(id: string, body: object) {
    const apiPath = `/project/${id}`;
    const response = APIService.put(apiPath, body);
    return response;
  }

  public updateProjectImage(id: string, docForm: object) {
    const apiPath = `/project/image/${id}`;
    const response = APIDocumentsService.put(apiPath, docForm);
    return response;
  }

  public getProjectDetailById(id: string) {
    const apiPath = `/project/${id}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public getFinalProjectDetailById(productId: string) {
    const apiPath = `/project/final/${productId}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public delete(id: string) {
    const apiPath = `/project/${id}`;
    const response = APIService.delete(apiPath);
    return response;
  }

  public addSingleUserToProject(body: any) {
    const apiPath = '/user-project';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public removeSingleUserFromProject(projectId: string, userId: string) {
    const apiPath = `/user-project/project/${projectId}/user/${userId}`;
    const response = APIService.delete(apiPath);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const ProjectsService = new Service();

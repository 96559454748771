import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

import applicationData from './modules/applicationData/applicationData';
import user from './modules/user/user';

const ls = new SecureLS({ isCompression: true });
const store = createStore({
  modules: {
    applicationData,
    user,
  },
  state() {
    return {
    };
  },
  // plugins: [createPersistedState()], // un comment this for testing
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  getters: {
  },
  mutations: {
  },
  actions: {
  },
});

export default store;

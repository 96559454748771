import { Options, Vue } from 'vue-class-component';
import { EnterprisesService } from '@/services/EnterprisesService';
import CreateEnterpriseDTO from '@/model/DTOs/CreateEnterpriseDTO';
import { mapMutations } from 'vuex';
import EnterpriseDTO from '@/model/DTOs/EnterpriseDTO';
import ModalCharging from '../ModalChargingComponent/ModalCharging.vue';

@Options({
  name: 'EnterpriseForm',
  components: {
    ModalCharging,
  },
  props: {
    // edit/view or creating
    usedFor: {
      type: String,
      required: true,
      validator(value: string) {
        // The value must match one of these strings
        return ['view', 'edit', 'creation'].includes(value);
      },
    },
  },
  data() {
    return {
      companyName: '',
      companyNit: '',
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations('applicationData', ['addNewEnterpriseData']),
    async sendCreationRequest() {
      try {
        this.isLoading = true;
        const newEnterprise = new CreateEnterpriseDTO({
          name: this.companyName,
          nit_id: this.companyNit,
        } as CreateEnterpriseDTO);
        const response = await EnterprisesService.createEnterprise(newEnterprise);
        const createdEnterprise = new EnterpriseDTO(response.data);
        this.addNewEnterpriseData(createdEnterprise);
        this.isLoading = false;
        this.companyName = '';
        this.companyNit = '';
      } catch (error) {
        alert(`Error creando nueva empresa: ${error}`);
        this.isLoading = false;
      }
    },
  },
})
export default class EnterpriseForm extends Vue {}

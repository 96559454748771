import { Options, Vue } from 'vue-class-component';

import HeaderApp from '@/components/HeaderApp/HeaderApp.vue';
import ProductDetail from '@/components/ProductDetail/ProductDetail.vue';
import ProductStages from '@/components/ProductStages/ProductStages.vue';
import { ProjectsService } from '@/services/ProjectsService';
import { ProductsService } from '@/services/ProductsService';
import { Product } from '@/model/Classes/Product';
import StageDTO from '@/model/DTOs/StageDTO';
import { Stage } from '@/model/Classes/Stage';
import ModalCharging from '@/components/ModalChargingComponent/ModalCharging.vue';
import { User } from '@/model/Classes/User';
import Contact from '@/model/Classes/Contact';

@Options({
  name: 'ProductView',
  props: {
    projectId: {
      type: String,
      required: false,
    },
  },
  components: {
    HeaderApp,
    ProductDetail,
    ProductStages,
    ModalCharging,
  },
  beforeMount() {
    this.getProjectData(this.projectId);
  },
  data() {
    return {
      products: [],
      firstProductPicked: '',
      stagesFromProduct: [],
      stageSelected: undefined,
      context: 'stage',
      isLoading: false,
      productPicked: undefined,
      usersOnProject: [],
      contactData: undefined,
      isLastStage: false,
    };
  },
  methods: {
    async getProjectData(id: string) {
      try {
        this.isLoading = true;
        const response = await ProjectsService.getProjectDetailById(id);
        this.contactData = new Contact({
          contactEmail: response.data.contact_email ? response.data.contact_email : 'No establecido',
          contactPhone: response.data.contact_phone ? response.data.contact_phone : 'No establecido',
          contactMeetingUrl: response.data.contact_meeting_url ? response.data.contact_meeting_url : 'No establecido',
        });
        this.products = response.data.products.map((product: any) => new Product({
          id: product.product_id,
          name: product.name,
          state: product.state,
          description: product.description,
          progress: product.progress || 0,
        }));
        this.firstProductPicked = this.products[0].id;
        const responseProduct = await ProductsService.getProductDetailById(this.products[0].id);
        this.productPicked = new Product({
          id: responseProduct.data.product_id,
          name: responseProduct.data.name,
          state: responseProduct.data.state,
          description: responseProduct.data.description,
          startDate: responseProduct.data.start_date,
          endDate: responseProduct.data.end_date,
          stagesNumer: responseProduct.data.stages.length,
          progress: responseProduct.data.progress || 0,
        });
        this.usersOnProject = response.data.users.map((obj: any) => new User({
          id: obj.user.user_id,
          name: obj.user.first_name,
          imgUrl: obj.user.img_url,
          headline: obj.user.profesional_headline,
          userProjectId: obj.user_project_id,
        }));
        this.prepareStageData(responseProduct.data.stages);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error al obtener datos del proyecto: ${error}`);
      }
    },
    async changeProductSelection(productId: string) {
      try {
        this.isLoading = true;
        this.stageContext = false;
        this.producContext = true;
        const response = await ProductsService.getProductDetailById(productId);
        this.productPicked = new Product({
          id: response.data.product_id,
          name: response.data.name,
          state: response.data.state,
          description: response.data.description,
          startDate: response.data.start_date,
          endDate: response.data.end_date,
          stagesNumer: response.data.stages.length,
          progress: response.data.progress || 0,
        });
        this.prepareStageData(response.data.stages);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error al obtener datos del producto: ${error}`);
      }
    },
    prepareStageData(stageData: StageDTO[]) {
      this.stagesFromProduct = stageData.map((stage) => new Stage({
        id: stage.stage_id,
        name: stage.name,
        state: stage.state,
        progress: stage.progress || 0,
        startDate: stage.start_date,
        endDate: stage.end_date,
        order: stage.order,
      }));
      this.setStageInProgress();
    },
    setStageInProgress() {
      let stageIndex = 0;
      this.stagesFromProduct.forEach((stage: Stage, index: number) => {
        if (stage.state === 'progress') {
          stageIndex = index;
        }
      });
      this.stageSelected = this.stagesFromProduct[stageIndex];
    },
    stagePicked(stage: Stage) {
      this.setStageContext();
      this.stageSelected = stage;
      this.isLastStage = false;
    },
    lastStagePicked(stage: Stage) {
      this.setStageContext();
      this.stageSelected = stage;
      this.isLastStage = true;
    },
    setProductContext() {
      this.context = 'product';
    },
    setStageContext() {
      this.context = 'stage';
    },
    refreshProjectData() {
      this.getProjectData(this.projectId);
    },
  },
})

export default class ProductView extends Vue {}

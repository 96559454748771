/* eslint-disable class-methods-use-this */
import { CreateBaseProductDTO } from '@/model/DTOs/CreateBaseProductDTO';
import { APIService } from './ApiService';

class Service {
  public getProductDetailById(productId: string) {
    const apiPath = `/product/${productId}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public createBaseProduct(baseProduct: CreateBaseProductDTO) {
    const apiPath = '/product/components';
    const response = APIService.post(apiPath, baseProduct);
    return response;
  }

  public updateProduct(id: string, body: object) {
    const apiPath = `/product/${id}`;
    const response = APIService.put(apiPath, body);
    return response;
  }

  public deleteById(id: string) {
    const apiPath = `/product/${id}`;
    const response = APIService.delete(apiPath);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const ProductsService = new Service();

import { User } from './User';

export class Document {
  public id?: number

  public name?: string

  public url?: string

  public creationDate?: string

  public owner?: User

  constructor(data: Document) {
    this.id = data.id;
    this.name = data.name;
    this.url = data.url;
    this.creationDate = data.creationDate;
    this.owner = data.owner;
  }
}

export const DocumentsArray = [
  new Document({
    id: 1,
    name: 'Document 1',
    url: 'https://platzi.com/home',
  }),
  new Document({
    id: 2,
    name: 'Document 2 shorter',
    url: 'https://platzi.com/home',
  }),
  new Document({
    id: 3,
    name: 'Document 3',
    url: 'https://platzi.com/home',
  }),
  // new Document({
  //   id: 4,
  //   name: 'Document 3',
  //   url: 'https://platzi.com/home',
  // }),
  // new Document({
  //   id: 5,
  //   name: 'Document 3',
  //   url: 'https://platzi.com/home',
  // }),
  // new Document({
  //   id: 6,
  //   name: 'Document 3',
  //   url: 'https://platzi.com/home',
  // }),
  // new Document({
  //   id: 7,
  //   name: 'Document 4 lon name',
  //   url: 'https://platzi.com/home',
  // }),
];

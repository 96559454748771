import { Options, Vue } from 'vue-class-component';
import { Project } from '@/model/Classes/Project';
import { mapActions, mapGetters } from 'vuex';
import { ProjectsService } from '@/services/ProjectsService';
import ModalCharging from '@/components/ModalChargingComponent/ModalCharging.vue';

@Options({
  name: 'GalleryCard',
  components: {
    ModalCharging,
  },
  props: {
    cardProject: {
      type: Object as () => Project,
      required: false,
    },
    addingCard: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      cardName: '',
      addCard: new Project({
        id: '0',
        name: 'Crear Proyecto',
        /* eslint-disable */
        imgUrl: require('@/assets/images/addCard.png'),
        /* eslint-enable */
      }),
      isLoading: false,
    };
  },
  mounted() {
    // this.addCard =
  },
  computed: {
    ...mapGetters('user', ['isUserAdmin']),
  },
  methods: {
    createProject() {
      if (this.addingCard) {
        this.$emit('createProject');
      }
    },
    pickProject() {
      if (this.addingCard) {
        this.$emit('pick-project', this.addCard);
      } else {
        this.$emit('pick-project', this.cardProject);
      }
    },
    deleteProject() {
      try {
        this.isLoading = true;
        // eslint-disable-next-line no-restricted-globals
        const response = confirm('----Los documentos asociados al proyecto persistirán en Google Drive, sin embargo no se podrá recuperar la data asociada al proyecto---- ¿Está seguro de eliminar el proyecto?');
        if (response) {
          ProjectsService.delete(this.cardProject.id);
          this.$emit('deleted-project', this.cardProject.id);
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        alert('Error al eliminar el proyecto');
      }
    },
  },
})

export default class GalleryCard extends Vue {}

/* eslint-disable camelcase */
export interface ICreateCommentDTO {
  description: string;
  date: string;
  user_id?: string;
  task_id: string;
}

export default class CreateCommentDTO {
  public description: string;

  public date: string;

  public user_id?: string;

  public task_id: string;

  constructor(data: ICreateCommentDTO) {
    this.description = data.description;
    this.date = data.date;
    this.user_id = data.user_id;
    this.task_id = data.task_id;
  }
}

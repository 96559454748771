import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'ModalComponent',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      /* eslint-disable */
      closeIcon: require('@/assets/icons/check-icon.png'),
      /* eslint-enable */
    };
  },
  watch: {
    open(change: boolean) {
      if (change === true) {
        this.$emit('modal-opened', change);
      }
    },
  },
  methods: {
    openDateAppointer() {
      window.open('https://www.w3schools.com', '_blank');
    },
    closeModal() {
      this.$emit('close-modal');
    },
  },
})

export default class ModalComponent extends Vue {}

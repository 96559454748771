export class Stage {
  public id?: string

  public name?: string

  public progress?: number

  public startDate?: string

  public endDate?: string

  public imgUrl?: string

  public users?: string[]

  public products?: string[]

  public state?: string

  public stageIndex?: number

  public stageElevation?: number

  public urlPinImg?: string

  public order?: number

  constructor(data: Stage) {
    this.id = data.id;
    this.name = data.name;
    this.progress = data.progress;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.imgUrl = data.imgUrl;
    this.users = data.users;
    this.products = data.products;
    this.state = data.state;
    this.stageIndex = data.stageIndex;
    this.stageElevation = data.stageElevation;
    this.urlPinImg = data.urlPinImg;
    this.order = data.order;
  }
}
/* eslint-disable */
export const StagesArray = [
  new Stage({
    id: 'testid',
    name: 'Stage 1',
    state: 'In progress',
    // startDate: new Date('12/02/2023'),
  }),
  new Stage({
    id: 'testid',
    name: 'Stage 2',
    state: 'In progress',
    // startDate: new Date('12/02/2023'),
  }),
  new Stage({
    id: 'testid',
    name: 'Stage 3',
    state: 'In progress',
    // startDate: new Date('12/02/2023'),
  }),
  new Stage({
    id: 'testid',
    name: 'Stage 4',
    state: 'In progress',
    // startDate: new Date('12/02/2023'),
  }),
  new Stage({
    id: 'testid',
    name: 'Stage 5',
    state: 'In progress',
    // startDate: new Date('12/02/2023'),
  }),
  /* eslint-enable */
  // new Stage({
  //   id: 'testid',
  //   name: 'Stage 3',
  //
  //   state: 'In progress',
  // startDate: new Date('12/02/2023'),
  // }),
  // new Stage({
  //   id: 'testid',
  //   name: 'Stage 4 lon name',
  //
  //   state: 'In progress',
  // startDate: new Date('12/02/2023'),
  // }),
];

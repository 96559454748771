import UserDTO from './UserDTO';

/* eslint-disable camelcase */
export interface ISendAuthDTO {
  username: string;
  password: UserDTO;
}

export default class SendAuthDTO {
  public username: string;

  public password: UserDTO;

  constructor(data: ISendAuthDTO) {
    this.username = data.username;
    this.password = data.password;
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3252d220"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "generalContainer" }
const _hoisted_2 = { class: "graphicContainer" }
const _hoisted_3 = { class: "graphicContainer__mountains" }
const _hoisted_4 = { class: "productDetail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalCharging = _resolveComponent("ModalCharging")!
  const _component_HeaderApp = _resolveComponent("HeaderApp")!
  const _component_ProductStages = _resolveComponent("ProductStages")!
  const _component_ProductDetail = _resolveComponent("ProductDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalCharging, { open: _ctx.isLoading }, null, 8, ["open"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HeaderApp, {
        "header-type": 'products',
        products: _ctx.products,
        "first-product-picked": _ctx.firstProductPicked,
        "contact-data": _ctx.contactData,
        "project-id": _ctx.projectId,
        onChangeProductSelection: _ctx.changeProductSelection,
        onClickOnProducts: _ctx.setProductContext,
        onUpdateContactInfo: _ctx.refreshProjectData
      }, null, 8, ["products", "first-product-picked", "contact-data", "project-id", "onChangeProductSelection", "onClickOnProducts", "onUpdateContactInfo"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ProductStages, {
          stages: _ctx.stagesFromProduct,
          onStagePicked: _ctx.stagePicked,
          onLastStagePicked: _ctx.lastStagePicked
        }, null, 8, ["stages", "onStagePicked", "onLastStagePicked"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ProductDetail, {
        "stage-selected": _ctx.stageSelected,
        context: _ctx.context,
        stages: _ctx.stagesFromProduct,
        "product-picked": _ctx.productPicked,
        "project-id": _ctx.projectId,
        "number-of-products": _ctx.products.length,
        "users-on-project": _ctx.usersOnProject,
        "is-last-stage": _ctx.isLastStage,
        onNewStageCreated: _ctx.refreshProjectData,
        onNewProductCreated: _ctx.refreshProjectData,
        onStageUpdated: _ctx.refreshProjectData,
        onProductUpdated: _ctx.refreshProjectData,
        onStageDeleted: _ctx.refreshProjectData,
        onProductDeleted: _ctx.refreshProjectData
      }, null, 8, ["stage-selected", "context", "stages", "product-picked", "project-id", "number-of-products", "users-on-project", "is-last-stage", "onNewStageCreated", "onNewProductCreated", "onStageUpdated", "onProductUpdated", "onStageDeleted", "onProductDeleted"])
    ])
  ]))
}
/* eslint-disable class-methods-use-this */
import { APIService } from './ApiService';

class Service {
  public getCategories() {
    const apiPath = '/category';
    const response = APIService.get(apiPath);
    return response;
  }

  public addNewCategory(body: any) {
    const apiPath = '/category';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public deleteCategory(id: string) {
    const apiPath = `/category/${id}`;
    const response = APIService.delete(apiPath);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const CategoriesService = new Service();

/* eslint-disable class-methods-use-this */
import CreateStageDTO from '@/model/DTOs/CreateStageDTO';
import { APIService } from './ApiService';

class Service {
  public createStage(body: CreateStageDTO) {
    const apiPath = '/stage';
    const response = APIService.post(apiPath, body);
    return response;
  }

  public updateStage(id: string, body: object) {
    const apiPath = `/stage/${id}`;
    const response = APIService.put(apiPath, body);
    return response;
  }

  public getStageById(id: string) {
    const apiPath = `/stage/${id}`;
    const response = APIService.get(apiPath);
    return response;
  }

  public deleteById(id: string) {
    const apiPath = `/stage/${id}`;
    const response = APIService.delete(apiPath);
    return response;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const StagesService = new Service();

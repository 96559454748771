import { Product } from '@/model/Classes/Product';
import { Options, Vue } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import Contact from '@/model/Classes/Contact';
import ContactModal from '../ContactModal/ContactModal.vue';
import ModalComponent from '../ModalComponent/ModalComponent.vue';
import UserOptionsModal from '../UserOptionsModal/UserOptionsModal.vue';

@Options({
  name: 'HeaderApp',
  props: {
    headerType: {
      type: String,
      default: 'projects',
    },
    products: {
      type: Object as () => Product[],
      required: false,
    },
    firstProductPicked: {
      type: String,
      required: false,
    },
    contactData: {
      type: Object as () => Contact,
      required: false,
    },
    projectId: {
      type: String,
      required: false,
    },
  },
  components: {
    ContactModal,
    ModalComponent,
    UserOptionsModal,
  },
  data() {
    return {
      openModalContact: false,
      openModalUser: false,
      /* eslint-disable */
      enterpriseIcon: require('@/assets/icons/filter-icon.png'),
      categoryIcon: require('@/assets/icons/filter-icon.png'),
      stateIcon: require('@/assets/icons/filter-icon.png'),
      userImg: require('@/assets/images/user.png'),
      /* eslint-enable */
      productPicked: '',
      filterCategory: '',
      filterEnterprise: '',
      filterState: '',
    };
  },
  watch: {
    firstProductPicked(newVal: string) {
      this.productPicked = newVal;
    },
  },
  computed: {
    ...mapState('user', ['uniqueEnterprises']),
    ...mapGetters('user', ['getHeaderName', 'getUrlProfileImage', 'isUserAdmin']),
    ...mapState('applicationData', ['categories']),
  },
  methods: {
    changeFilterEnterprise() {
      this.$emit('change-enterprise-filter', this.filterEnterprise);
    },
    changeFilterState() {
      this.$emit('change-state-filter', this.filterState);
    },
    changeFilterCategory() {
      this.$emit('change-category-filter', this.filterCategory);
    },
    changeProduct() {
      this.$emit('change-product-selection', this.productPicked);
    },
    openContactModal() {
      this.openModalContact = true;
    },
    closeContactModal() {
      this.openModalContact = false;
    },
    openUserModal() {
      this.openModalUser = true;
    },
    closeUserModal() {
      this.openModalUser = false;
    },
    goHome() {
      this.$router.push({ name: 'projects' });
    },
    clickOnProducts() {
      this.$emit('click-on-products');
    },
    emitUpdateContacInfo() {
      this.$emit('update-contact-info');
    },
  },
})

export default class HeaderApp extends Vue {}

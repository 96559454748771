import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'ModalCharging',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      /* eslint-disable */
      loadingGif: require('@/assets/images/cordadaLoading.gif'),
      /* eslint-enable */
    };
  },
  watch: {
    open(newVal) {
      if (newVal) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  computed: {
  },
  methods: {
    openDateAppointer() {
      window.open('https://www.w3schools.com', '_blank');
    },
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('close-modal');
    },
  },
})

export default class ModalCharging extends Vue {}

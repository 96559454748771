export class Product {
  public id?: string

  public name?: string

  public description?: string

  public progress?: number

  public startDate?: Date

  public endDate?: Date

  public imgUrl?: string

  public users?: string[]

  public products?: string[]

  public state?: string

  public stagesNumer?: number

  constructor(data: Product) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.progress = data.progress;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.imgUrl = data.imgUrl;
    this.users = data.users;
    this.products = data.products;
    this.state = data.state;
    this.stagesNumer = data.stagesNumer;
  }
}

export const ProductsArray = [
  new Product({
    id: 'testid',
    name: 'Product 1',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-Product-management-body-of-knowledge-Product-management-professional-Product-manager-management-Product-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Product({
    id: 'testid',
    name: 'Product 2 shorter',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-Product-management-body-of-knowledge-Product-management-professional-Product-manager-management-Product-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Product({
    id: 'testid',
    name: 'Product 3',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-Product-management-body-of-knowledge-Product-management-professional-Product-manager-management-Product-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Product({
    id: 'testid',
    name: 'Product 3',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-Product-management-body-of-knowledge-Product-management-professional-Product-manager-management-Product-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Product({
    id: 'testid',
    name: 'Product 3',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-Product-management-body-of-knowledge-Product-management-professional-Product-manager-management-Product-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Product({
    id: 'testid',
    name: 'Product 3',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-Product-management-body-of-knowledge-Product-management-professional-Product-manager-management-Product-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
  new Product({
    id: 'testid',
    name: 'Product 4 lon name',
    imgUrl: 'https://e7.pngegg.com/pngimages/408/212/png-clipart-Product-management-body-of-knowledge-Product-management-professional-Product-manager-management-Product-miscellaneous-text-thumbnail.png',
    state: 'In progress',
  }),
];

import { Options, Vue } from 'vue-class-component';

import GalleryCard from '@/components/GalleryCard/GalleryCard.vue';
import GalleryCardDescription from '@/components/GalleryCardDescription/GalleryCardDescription.vue';
import HeaderApp from '@/components/HeaderApp/HeaderApp.vue';
import { Project } from '@/model/Classes/Project';
import { mapActions, mapGetters, mapState } from 'vuex';

@Options({
  name: 'ProjectGallery',
  components: {
    HeaderApp,
    GalleryCard,
    GalleryCardDescription,
  },
  data() {
    return {
      gallery: 'ProjectGallery',
      username: 'User Name',
      projects: [] as Project[],
      projectPicked: {} as Project,
      isProjectPicked: false,
      creatingProject: false,
      visibleProjects: [] as Project[],
      isLoading: false,
    };
  },
  computed: {
    ...mapState('user', ['userProjectsTransformed']),
    ...mapGetters('user', ['isUserAdmin', 'isUserProducer']),
  },
  watch: {
    userProjectsTransformed() {
      this.visibleProjects = this.userProjectsTransformed;
    },
  },
  mounted() {
    this.isLoading = true;
    this.visibleProjects = this.userProjectsTransformed;
    if (this.isUserAdmin) {
      this.getAdminProjects();
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions('user', ['getAdminProjects']),
    changeProjectPicked(project: Project) {
      this.projectPicked = project;
      this.isProjectPicked = true;
      this.creatingProject = false;
    },
    createProject() {
      this.projectPicked = undefined;
      this.isProjectPicked = true;
      this.creatingProject = true;
    },
    changeFilterEnterprise(filterEnterpriseId: string) {
      if (filterEnterpriseId === 'all') {
        this.visibleProjects = this.userProjectsTransformed;
      } else {
        this.visibleProjects = this.userProjectsTransformed.filter((project: Project) => {
          if (filterEnterpriseId === '') {
            return true;
          }
          return project.enterpriseId === filterEnterpriseId;
        });
      }
    },
    changeFilterState(filterState: string) {
      if (filterState === 'all') {
        this.visibleProjects = this.userProjectsTransformed;
      } else {
        this.visibleProjects = this.userProjectsTransformed.filter((project: Project) => {
          if (filterState === '') {
            return true;
          }
          return project.state === filterState;
        });
      }
    },
    changeFilterCategory(filterCategory: string) {
      if (filterCategory === 'all') {
        this.visibleProjects = this.userProjectsTransformed;
      } else {
        this.visibleProjects = this.userProjectsTransformed.filter((project: Project) => {
          if (filterCategory === '') {
            return true;
          }
          return project.category === filterCategory;
        });
      }
    },
    closeDescriptionContainer() {
      this.isProjectPicked = false;
    },
    deleteProject() {
      this.getAdminProjects();
    },
    refreshProjects() {
      this.getAdminProjects();
    },
  },
})

export default class ProjectGallery extends Vue {}

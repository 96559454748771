import ViewTask from './ViewTask';

export default class Task {
  public id?: string

  public name?: string

  public imgUrl?: string

  public description?: string

  public asignees?: string

  public creationDate?: string

  public deliverDate?: string

  public subTasks?: Task[]

  public state?: string

  public folderDriveId?: string

  public isPublic?: boolean

  public visibleUserIds?: ViewTask[]

  constructor(data: Task) {
    this.id = data.id;
    this.name = data.name;
    this.imgUrl = data.imgUrl;
    this.description = data.description;
    this.asignees = data.asignees;
    this.creationDate = data.creationDate;
    this.deliverDate = data.deliverDate;
    this.subTasks = data.subTasks;
    this.state = data.state;
    this.folderDriveId = data.folderDriveId;
    this.isPublic = data.isPublic;
    this.visibleUserIds = data.visibleUserIds;
  }
}

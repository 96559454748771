import { Options, Vue } from 'vue-class-component';

import HeaderApp from '@/components/HeaderApp/HeaderApp.vue';
import UserForm from '@/components/UserForm/UserForm.vue';
import { UsersService } from '@/services/UsersService';
import ModalCharging from '@/components/ModalChargingComponent/ModalCharging.vue';
import UserDTO from '@/model/DTOs/UserDTO';

@Options({
  name: 'UserManagement',
  components: {
    HeaderApp,
    UserForm,
    ModalCharging,
  },
  data() {
    return {
      username: '',
      users: [],
      /* eslint-disable */
      userImg: require('@/assets/images/user.png'),
      /* eslint-enable */
      activeUserManagement: false,
      pickedUser: undefined,
      isLoading: false,
    };
  },
  mounted() {
    this.getAllUsersRegistered();
  },
  computed: {
  },
  methods: {
    manageUser(user: UserDTO) {
      this.activeUserManagement = true;
      this.pickedUser = user;
    },
    cancelUserManagement() {
      this.activeUserManagement = false;
      this.pickedUser = undefined;
    },
    async deleteUser(user: UserDTO) {
      try {
        this.isLoading = true;
        // eslint-disable-next-line no-restricted-globals
        const response = confirm('¿Está seguro de que desea eliminar este usuario? No se podrán deshacer los cambios.');
        if (response) {
          await UsersService.deleteUser(user.user_id);
          // this.getUsersFromEnterprise();
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        // this.getUsersFromEnterprise();
        alert(`Error eliminando el usuario: ${error}`);
      }
    },
    async disableUser(user: UserDTO) {
      try {
        this.isLoading = true;
        // eslint-disable-next-line no-restricted-globals
        const response = confirm('¿Está seguro de que desea deshabilitar este usuario?');
        this.isLoading = false;
        // const data = {
        //   disabled: true,
        // };
        // if (response) {
        //   await UsersService.updateUser(user.user_id, data);
        //   this.isLoading = false;
        // } else {
        //   this.isLoading = false;
        // }
      } catch (error) {
        this.isLoading = false;
        alert(`Error eliminando el usuario: ${error}`);
      }
    },
    async getAllUsersRegistered() {
      try {
        this.isLoading = true;
        const response = await UsersService.getAllUsers();
        this.users = response.data
          .sort((a: any, b:any) => ((a.enterprise.name > b.enterprise.name) ? 1 : -1));
        console.log(this.users);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error eliminando el usuario: ${error}`);
      }
    },
  },
})
export default class UserManagement extends Vue {}

import { Options, Vue } from 'vue-class-component';

import utils from '@/utils/utils';
import { UsersService } from '@/services/UsersService';

@Options({
  name: 'ResetPasswordView',
  props: {
    confirmationToken: {
      type: String,
      required: true,
    },
    resetEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newPassword: '',
      newPasswordConfirmation: '',
    };
  },
  methods: {
    async requestPasswordReset() {
      try {
        if (this.newPassword === this.newPasswordConfirmation) {
          const body = {
            password: this.newPassword,
            resetPasswordToken: this.confirmationToken,
          };
          await UsersService.resetPassword(body);
          alert('Contraseña re-establecida');
        } else {
          alert('Las contraseñas no coinciden');
        }
      } catch (error) {
        alert(`Ocurrió un error al hacer la petición: ${error}`);
      }
    },
  },
})

export default class ResetPasswordView extends Vue {}

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66cce590"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pickerContainer" }
const _hoisted_2 = { class: "square-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "pickerContainer__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "profileImage",
        src: _ctx.user.imgUrl,
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.formatName(_ctx.user.name)), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.formatName(_ctx.user.headline)), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.formatName(_ctx.user.profile)), 1)
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("span", {
        class: "material-symbols-outlined",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeUserFrom && _ctx.removeUserFrom(...args)))
      }, " person_add_disabled ")
    ], 512), [
      [_vShow, _ctx.editing]
    ])
  ]))
}
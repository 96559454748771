import axios, { AxiosInstance } from 'axios';
import utils from '@/utils/utils';

class Service {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      // baseURL: 'https://lacordadarest.onrender.com/api',
      // baseURL: 'https://compasrest.onrender.com/api',
      baseURL: 'https://compasrest.lacordadaestudio.com/api',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  addTokenToRequest() {
    const token = utils.getTokenFromCookie();
    this.client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  public get(path: string) {
    this.addTokenToRequest();
    const request = this.client.get(path);
    return request;
  }

  public post(path: string, data: object) {
    this.addTokenToRequest();
    const request = this.client.post(path, data);
    return request;
  }

  public put(path: string, data: object) {
    this.addTokenToRequest();
    const request = this.client.put(path, data);
    return request;
  }

  public patch(path: string, data: object) {
    this.addTokenToRequest();
    const request = this.client.patch(path, data);
    return request;
  }

  public delete(path: string, data?: object) {
    this.addTokenToRequest();
    const request = this.client.delete(path);
    return request;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const APIDocumentsService = new Service();

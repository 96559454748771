/* eslint-disable camelcase */
export interface ICreateStageDTO {
  name: string;
  state: string;
  progress: number;
  description?: string;
  product_id?: string;
  order?: number;
  start_date?: string;
  end_date?: string;
}

export default class CreateStageDTO {
  public name: string;

  public state: string;

  public progress: number;

  public product_id?: string;

  public description?: string;

  public order?: number;

  public start_date?: string;

  public end_date?: string;

  constructor(data: ICreateStageDTO) {
    this.name = data.name;
    this.state = data.state;
    this.progress = data.progress;
    this.product_id = data.product_id;
    this.description = data.description;
    this.order = data.order;
    this.start_date = data.start_date;
    this.end_date = data.end_date;
  }
}
